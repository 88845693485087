import React, { useState } from 'react';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import { CardGiftcard } from '@mui/icons-material';
import { Col, Row } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';

function AddClinicForm() {
  const [clinicData, setClinicData] = useState({
    clinicName: '',
    address1: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClinicData({
      ...clinicData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here, e.g., send data to server or perform actions
    console.log('Form data submitted:', clinicData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="name"
            variant="outlined"
            placeholder="First Name"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaUser />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            name="name"
            variant="outlined"
            placeholder="Last Name"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaUser />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            name="email"
            variant="outlined"
            placeholder="Email"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address1"
            variant="outlined"
            placeholder="User Role"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CardGiftcard />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
      </Grid>

      <Row className="mt-5 align-items-end">
        <Col xs={6}>
          <Button
            type="submit"
            variant="contained"
            className='btn btn-outline-secondary'
          >
            Back
          </Button>
        </Col>
        <Col xs={6} className="text-end">
          <Button
            type="submit"
            variant="contained"
            style={{
              background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
            }}
          >
            Create
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default AddClinicForm;
