import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import DashboardLineChart from "./chart/DashboardLineChart";
import InfoCards from '../components/dashboard/InfoCards'
import DashboardBarChart from './chart/DashboardBarChart';

const DashboardAppPage = () => {
  return (
    <div>
      <Container>
        <Row className='mb-5'>
          <InfoCards />
        </Row>
        <Row cllassName='mt-5'>
        <Col md={6}>
                <Card className='px-3 shadow-sm rounded-3' border='0'>
                    <DashboardBarChart/>
                    <div className='mt-3'>
                        <h6 className='mb-0 pb-0 text-dark'>Patient Registrations</h6>
                        <small className='mt-0 pt-0' style={{color:'#ccc'}}>Monthly Graph</small>
                    </div>
                  </Card>
                </Col>

            <Col md={6}>
                <Card className='shadow-sm px-3  rounded-3' border='0' >
                    <DashboardLineChart/>
                    <div className='mt-3'>
                        <h6 className='mb-0 pb-0 text-dark'>Surveys Completed</h6>
                        <small className='mt-0 pt-0' style={{color:'#ccc'}}>This week</small>
                    </div>
                </Card>
            </Col>

            
        </Row>

      </Container>
    </div>
  )
}

export default DashboardAppPage

