import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

const MultipleChoiceOptionB = () => {

  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
        <Form className='radio-tabs'>

          <div className='d-flex align-items-center mt-4'>
            <input type="text" className='ps-4 py-3 w-100'  placeholder='Enter a number choice' id="textInput" style={{height:'30px',width:'100%',border:'1px solid #F3F3F3'}} />
            <FaPlusCircle type='button' className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}} />
            <FaMinusCircle type='button'  className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}}/>
          </div>

          <div className='d-flex align-items-center mt-4'>
            <input type="text" className='ps-4 py-3 w-100'  placeholder='Enter a number choice' id="textInput" style={{height:'30px',width:'100%',border:'1px solid #F3F3F3'}} />
            <FaPlusCircle type='button' className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}} />
            <FaMinusCircle type='button'  className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}}/>
          </div>

          <div className='d-flex align-items-center mt-4'>
            <input type="text" className='ps-4 py-3 w-100'  placeholder='Enter a number choice' id="textInput" style={{height:'30px',width:'100%',border:'1px solid #F3F3F3'}} />
            <FaPlusCircle type='button' className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}} />
            <FaMinusCircle type='button'  className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}}/>
          </div>
        </Form>
    </div>
  )
}

export default MultipleChoiceOptionB
