import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';


function ButtonCustomExample() {
  return (
    <>
    {/* <Link to='/dashboard/CreateQuestion'>  */}
      <Dropdown as={ButtonGroup}>
        <Link className='btn btn-primary bg-primary' to='/dashboard/CreateQuestion'>Add a Question</Link>
        <Dropdown.Toggle split className='bg-primary' id="dropdown-custom-2" />
        <Dropdown.Menu className="super-colors">
          <Dropdown.Item eventKey="1"><Link to='/dashboard/UpdateQuestions' className='text-secondary' style={{textDecoration:'none'}}>Update Questions</Link> </Dropdown.Item>
          <Dropdown.Item eventKey="2"> <Link to='/dashboard/GAD' className='text-secondary' style={{textDecoration:'none'}}>GAD-1</Link></Dropdown.Item>
          <Dropdown.Item eventKey="3"><Link to='/dashboard/SkipLogic' className='text-secondary' style={{textDecoration:'none'}}>Skip Logic</Link></Dropdown.Item>
          <Dropdown.Item eventKey="3"><Link to='/dashboard/OutcomeMeasureQuestion' className='text-secondary' style={{textDecoration:'none'}}>Outcome Measure</Link></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* </Link> */}
    </>
  );
}

export default ButtonCustomExample;