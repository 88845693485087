import React from 'react'
import { Container , Row, Col} from 'react-bootstrap'
import { FaQuestionCircle, FaSnapchat } from 'react-icons/fa'

const ScoringImplement = () => {
  return (
    <div>
      <Container>
            <Row className='mt-4 shadow-sm p-3 justify-content-between' style={{background:'#F6F7F9',color:'#7D7D7D'}}>
                <Col sm={1} className="me-2 pe-2">
                    <div className='pe-3'>
                        <input type="text" className='text-center pe-3' placeholder='1' id="textInput" style={{height:'40px',border:'1px solid #EAEBEC',width:'140px' }} />
                    </div>

                </Col>
                <Col sm={10} className="pe-0">
                    <div className='bg-white px-2 d-flex justify-content-between align-items-center' style={{height:'40px',border:'1px solid #EAEBEC'}}>
                        <span>What is your activity level?</span>                       
                    </div>
                </Col>
            </Row>

            <Row className='justify-content-center mt-4' style={{fontSize:'14px',fontWeight:'600'}}>
            <Col md={8} style={{color:'#7F7F7F'}}>
              <div className='mt-4'>
                <div className='d-flex align-items-center mb-2'>
                    <p style={{fontSize:'15px',fontWeight:'600',width:'160px'}}>
                    <FaQuestionCircle  className='me-3'/> Answer:
                    </p>

                    <p style={{color:'#1973E9'}}> %</p>
                </div>
                <div className='d-flex align-items-center mb-2'>
                    <ul style={{width:'160px'}}>
                        <li className='mb-2'>Not active at all</li>
                    </ul>
                    <input type="text" className='text-center' id="textInput" style={{height:'30px',width:'100px',border:'1px solid #EAEBEC'}} />  
                </div>

                <div className='d-flex align-items-center mb-2'>
                    <ul style={{width:'160px'}}>
                        <li className='mb-2'>Somewhat active</li>
                    </ul>
                    <input type="text" className='text-center' id="textInput" style={{height:'30px',width:'100px',border:'1px solid #EAEBEC'}} />  
                </div>
                <div className='d-flex align-items-center mb-2'>
                    <ul style={{width:'160px'}}>
                        <li className='mb-2'>Active</li>
                    </ul>
                    <input type="text" className='text-center' id="textInput" style={{height:'30px',width:'100px',border:'1px solid #EAEBEC'}} />  
                </div>
                <div className='d-flex align-items-center mb-2'>
                    <ul style={{width:'160px'}}>
                        <li className='mb-2'>Hyper active</li>
                    </ul>
                    <input type="text" className='text-center' id="textInput" style={{height:'30px',width:'100px',border:'1px solid #EAEBEC'}} />  
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default ScoringImplement
