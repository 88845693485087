// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Clinics',
    path: '/dashboard/',
    icon: icon('Group_1710'),
    children: [
      {
        title: 'Clinic List',
        path: '/dashboard/clinicList',
        icon: icon(''),
      },
      {
        title: 'Directors',
        path: '/dashboard/director',
        icon: icon(''),
      },
    ],
  },
  {
    title: 'Survey Management',
    path: '/dashboard/appoinment',
    icon: icon('appointment'),
    children: [
      {
        title: 'Surveys',
        path: '/dashboard/survey',
        icon: icon(''),
      },
      {
        title: 'Outcome Measures',
        path: '/dashboard/OutcomeMeasureCreateTable',
        icon: icon(''),
      },
    ],
  },
  {
    title: 'Reports',
    path: '/dashboard/',
    icon: icon('report'),
    children: [
      {
        title: 'Analysis',
        path: '/dashboard/comparativeAnalysis',
        icon: icon(''),
      },
    ],
  },
  // {
  //   title:'Comparative Analysis',
  //   path: '/dashboard/ComparativeAnalysis',
  //   icon: icon('ic_appointments'),
  // },
  // {
  //   title: 'Reports',
  //   path: '/dashboard/Charts',
  //   icon: icon('ic_reports'),
  // },
  {
    title: 'System Setup',
    path: '/dashboard/',
    icon: icon('surveys'),
    children: [
      {
        title: 'User Lists',
        path: '/dashboard/UserList',
        icon: icon(''),
      },
      {
        title: 'User Permission',
        path: '/dashboard/user-permission',
        icon: icon(''),
      },
      {
        title: 'User Logs',
        path: '/dashboard/UserLog',
        icon: icon(''),
      },
    ],
  },

  // {
  //   title: 'Survey Result',
  //   path: '/dashboard/blog',
  //   icon: icon(''),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
