import React from 'react';
import { Col, Container, Row, Card, Form } from 'react-bootstrap';
import CreateQuestionsFields from '../../components/form/CreateQuestionsFields';
import QuestionTypeSelect from '../../components/form/QuestionTypeSelect';

const CreateQuestion = () => {
  return (
    <div>
      <Container>
        <Row>
          {/* <Col>
               <QuestionTypeSelect />
            </Col> */}
          <Col>
            <Card className=" shadow-sm px-2 py-3" border="0">
              <Row className="px-3">
                <Col md={6}>
                  <h4 className="fw-bold text-secondary">Create Question</h4>
                </Col>
                <Col md={6} className="text-end">
                  <Form className="d-flex align-items-center justify-content-end text-end round-tog">
                    <span style={{ fontSize: '12px', fontWeight: '600', marginRight: '10px' }}>is required</span>
                    <Form.Check // prettier-ignore
                      id="custom-switch"
                      type="switch"
                    />
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CreateQuestionsFields />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateQuestion;
