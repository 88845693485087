

import React, { useState } from 'react';
import { TextField, Button, Grid, InputAdornment } from '@mui/material';
import { Link } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import { FolderZip, LocalHospitalOutlined, LocalHospitalTwoTone, LocationCity, LocationCityOutlined, LocationCityTwoTone } from '@mui/icons-material';
import { Col, Row } from 'react-bootstrap';
import { FaUser, FaUserTime } from 'react-icons/fa';

function WestEndClinicForm() {
  const [clinicData, setClinicData] = useState({
    clinicName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClinicData({
      ...clinicData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission here, e.g., send data to server or perform actions
    console.log('Form data submitted:', clinicData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
      <Grid item xs={12}>
          <TextField
            fullWidth
            name="clinicName"
            variant="outlined"
            placeholder='Diractor'
            value='Dr Ruth Parker'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaUser />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="clinicName"
            variant="outlined"
            placeholder='West End Clinic'
            value='West End Clinic'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalHospitalTwoTone />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address1"
            variant="outlined"
            placeholder='Address 1'
            value='Street 121'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="address2"
            placeholder='Address 2'
            variant="outlined"
            value={clinicData.address2}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                   <LocationOnIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="city"
            placeholder='City'
            variant="outlined"
            value='Boston'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCity />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="state"
            placeholder='state'
            variant="outlined"
            value='MA'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityTwoTone />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="zip"
            placeholder='ZIP'
            variant="outlined"
            value='00011'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FolderZip />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="phone"
            variant="outlined"
            value='(617) 724-7792'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            required
          />
        </Grid>
      </Grid>

      <Row className='mt-5 align-items-end'>
                    <Col>

                        <Link className='btn btn-outline-secondary' 
                                to='/dashboard/ClinicList' 
                                style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', 
                                        padding:'13px 25px',
                                        fontSize:'13px' }}>Back
                        </Link>


                        

                    </Col>
                    <Col className='text-end'>
                    <Button 
                            type="submit" 
                            variant="contained"
                            style={{background: '#6A6A6A', 
                                    padding:'13px 25px',
                                    border:'none', 
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;'}}>Save Changes
                                    
                        </Button>  
                    </Col>
                </Row>
      {/* <Button type="submit" variant="contained" color="primary">
        Submit
      </Button> */}
     
    </form>
  );
}

export default WestEndClinicForm;
