import React, { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap'


const RaceEthnicity = () => {

    const [show, toggleShow] = useState(true);

    const [selectedOption, setSelectedOption] = useState(''); // Initialize with an empty string or 'yes'/'no'

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };

  return (
    <div>
      <Container>
        <Row>
            <Col md={12}>
            {show && <Card className=' shadow-sm px-2' border='0'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h6 className='text-secondary mt-3'>Race/ethnicity</h6>
                        <button onClick={() => toggleShow(!show)} className='text-secondary bg-white border-0'>x</button>
                    </div>
                                <label htmlFor="yesOption" className='text-secondary mb-2 ' style={{fontSize:'14px'}}>
                                <input
                                    type="radio"
                                    id="yesOption"
                                    value="yes"
                                    checked={selectedOption === 'yes'}
                                    onChange={handleOptionChange}
                                    className='me-3'
                                    />
                                    American Indian
                                   
                                </label>


                                <label htmlFor="noOption"  className='text-secondary mb-2' style={{fontSize:'14px'}}>
                                
                                    <input
                                    type="radio"
                                    id="noOption"
                                    value="no"
                                    checked={selectedOption === 'no'}
                                    onChange={handleOptionChange}
                                    className='me-3'
                                    />
                                    Asian 
                                </label>

                                <label htmlFor="noOption"  className='text-secondary mb-2' style={{fontSize:'14px'}}>
                                
                                    <input
                                    type="radio"
                                    id="noOption1"
                                    value="other"
                                    checked={selectedOption === 'no'}
                                    onChange={handleOptionChange}
                                    className='me-3'
                                    />
                                    Black/African American 
                                </label>

                                <label htmlFor="noOption"  className='text-secondary mb-2' style={{fontSize:'14px'}}>
                                
                                    <input
                                    type="radio"
                                    id="noOption1"
                                    value="other"
                                    checked={selectedOption === 'no'}
                                    onChange={handleOptionChange}
                                    className='me-3'
                                    />
                                    Hispanic 
                                </label>
                                <label htmlFor="noOption"  className='text-secondary mb-2' style={{fontSize:'14px'}}>
                                
                                <input
                                type="radio"
                                id="noOption1"
                                value="other"
                                checked={selectedOption === 'no'}
                                onChange={handleOptionChange}
                                className='me-3'
                                />
                                Native Hawaiian 
                            </label>
                                <label htmlFor="noOption"  className='text-secondary mb-2' style={{fontSize:'14px'}}>
                                
                                    <input
                                    type="radio"
                                    id="noOption1"
                                    value="other"
                                    checked={selectedOption === 'no'}
                                    onChange={handleOptionChange}
                                    className='me-3'
                                    />
                                    White/Caucasian
                                </label>
                                <label htmlFor="noOption"  className='text-secondary mb-2' style={{fontSize:'14px'}}>
                                
                                    <input
                                    type="radio"
                                    id="noOption1"
                                    value="other"
                                    checked={selectedOption === 'no'}
                                    onChange={handleOptionChange}
                                    className='me-3'
                                    />
                                    Some other group
                                </label>
                            
                </Card>}
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RaceEthnicity
