import {useState} from 'react'
import { Col, Container, Row,Card,Form,Dropdown } from 'react-bootstrap'
import { FaToolbox, FaChartBar, FaSquare } from 'react-icons/fa'
import Barchart1 from '../../components/charts/BarChart'
import ChartBottomHeading from './ChartBottomHeading'
import ChartTopHeading from './ChartTopHeading'

const BarChart = () => {


  const [show, toggleShow] = useState(true);
  const [show2, toggleShow2] = useState(true);
  const [show3, toggleShow3] = useState(true);


  return (
    <div>
      <Container>

      <Row>
          <Col md={8}>
            <div className='d-flex align-items-center mb-4'>
              <h6 className='text-muted  pt-2 me-5'>Showing report for</h6>
               {show &&<span className=' px-2 me-2 text-muted' style={{border:'1px solid #e6e6e6', fontSize:'13px'}}>Male <button onClick={() => toggleShow(!show)} className='text-warning bg-white border-0'>x</button></span>}
               {show2 &&<span className=' px-2 me-2 text-muted' style={{border:'1px solid #e6e6e6', fontSize:'13px'}}>Outcome Measures <button onClick={() => toggleShow2(!show2)} className='text-warning bg-white border-0'>x</button></span>}
               {show3 &&<span className=' px-2 me-2 text-muted' style={{border:'1px solid #e6e6e6', fontSize:'13px'}}>Race <button onClick={() => toggleShow3(!show3)} className='text-warning bg-white border-0'>x</button></span>}
            </div>
          </Col>
          <Col md={4} className='text-end'>
            <div className='d-flex align-items-center justify-content-around'>
              <span className='text-muted'  style={{fontSize:'13px'}}>Chart Type</span>
              <Form.Select aria-label="Default select example"  style={{width:'170px'}}>
                <option value="1">Bar</option>
                <option value="2">Line Graph</option>
                <option value="3">Pie Chart</option>
              </Form.Select>
              <FaToolbox className='p-2 me-3 rounded-2 text-light' style={{fontSize:'17px',background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',height:'38px',width:'38px'}}/>
            </div>
          </Col>

          </Row>
        <Row>
        <Col md={12} className='my-4'>
                <ChartTopHeading/>
            </Col>
            <Col md={5}>
                <Card className='px-3 shadow-sm rounded-3' border='0'>
                    <div className='d-flex align-items-center'>
                        <FaChartBar className='p-2 me-3 rounded-2 text-light' style={{fontSize:'17px',background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',height:'40px',width:'40px'}}/>
                        <div>
                            <h6 className='mb-0 pb-0 text-dark'>Heading</h6>
                            <small className='mt-0 pt-0' style={{color:'#ccc'}}>Sub-Heading</small>
                        </div>
                    </div>
                    
                    <Barchart1/>
                </Card>
            </Col>
            <Col md={5}>
                <Card className='px-3 shadow-sm rounded-3' border='0'>
                    <div className='d-flex align-items-center'>
                        <FaChartBar className='p-2 me-3 rounded-2 text-light' style={{fontSize:'17px',background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',height:'40px',width:'40px'}}/>
                        <div>
                            <h6 className='mb-0 pb-0 text-dark'>Heading</h6>
                            <small className='mt-0 pt-0' style={{color:'#ccc'}}>Sub-Heading</small>
                        </div>
                    </div>
                    
                    <Barchart1/>
                </Card>
            </Col>
            <Col md={12} className='my-4'>
                <ChartBottomHeading/>
            </Col>
            <Col md={5}>
                <Card className='px-3 shadow-sm rounded-3' border='0'>
                    <div className='d-flex align-items-center'>
                        <FaChartBar className='p-2 me-3 rounded-2 text-light' style={{fontSize:'17px',background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',height:'40px',width:'40px'}}/>
                        <div>
                            <h6 className='mb-0 pb-0 text-dark'>Heading</h6>
                            <small className='mt-0 pt-0' style={{color:'#ccc'}}>Sub-Heading</small>
                        </div>
                    </div>
                    
                    <Barchart1/>
                </Card>
            </Col>
            <Col md={5}>
                <Card className='px-3 shadow-sm rounded-3' border='0'>
                    <div className='d-flex align-items-center'>
                        <FaChartBar className='p-2 me-3 rounded-2 text-light' style={{fontSize:'17px',background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',height:'40px',width:'40px'}}/>
                        <div>
                            <h6 className='mb-0 pb-0 text-dark'>Heading</h6>
                            <small className='mt-0 pt-0' style={{color:'#ccc'}}>Sub-Heading</small>
                        </div>
                    </div>
                    
                    <Barchart1/>
                </Card>
            </Col>

          

        </Row>
      </Container>
    </div>
  )
}

export default BarChart
