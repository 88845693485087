import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaCopy, FaPen, FaTrash, FaPlusSquare, GrEdit } from 'react-icons/fa';

const SurveyTable = () => {
  return (
    <div>
      <Card>
        <div
          className="bg-dark px-3"
          style={{
            paddingTop: '15px',
            paddingBottom: '1px',
            background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
          }}
        >
          <Table striped="rows" className="clinic-table survey-table">
            <thead className="rounded-2" style={{}}>
              <tr className="py-0 m-2 bg-white rounded-2">
                <th>Survey Title</th>
                <th>Survey Description</th>
                <th>Status</th>
                <th>Last Updated</th>
                <th>No. of Questions</th>
                <th>Actions</th>
              </tr>
            </thead>
          </Table>
        </div>
        <div>
          <Table striped="rows" className="clinic-table survey-table">
            <tbody>
              <tr>
                <td>
                  <Link to="/dashboard/SurveyTabs">Test Survey Title</Link>
                </td>
                <td>Testing Description</td>
                <td>Active</td>
                <td>09/05/2023</td>
                <td>35</td>
                <td>
                  <Nav>
                    <NavDropdown
                      title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                      className="surveyDropdowm"
                    >
                      <NavDropdown.Item href="#action/3.1" className="dropDownItem">
                        <Link to="/dashboard/SurveyTabs" style={{ color: '#505050' }}>
                          <FaPen
                            style={{
                              background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                              padding: '5px',
                              borderRadius: '50%',
                              fill: '#fff',
                              fontSize: '20px',
                              marginRight: '5px',
                            }}
                          />{' '}
                          Edit
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Duplicate
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/dashboard/SurveyTabs">Test Survey Title</Link>
                </td>
                <td>Testing Description</td>
                <td>Active</td>
                <td>09/05/2023</td>
                <td>35</td>
                <td>
                  <Nav>
                    <NavDropdown
                      title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                      className="surveyDropdowm"
                    >
                      <NavDropdown.Item href="#action/3.1" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Edit
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Duplicate
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/dashboard/SurveyTabs">Test Survey Title</Link>
                </td>
                <td>Testing Description</td>
                <td>Active</td>
                <td>09/05/2023</td>
                <td>35</td>
                <td>
                  <Nav>
                    <NavDropdown
                      title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                      className="surveyDropdowm"
                    >
                      <NavDropdown.Item href="#action/3.1" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Edit
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Duplicate
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/dashboard/SurveyTabs">Test Survey Title</Link>
                </td>
                <td>Testing Description</td>
                <td>Active</td>
                <td>09/05/2023</td>
                <td>35</td>
                <td>
                  <Nav>
                    <NavDropdown
                      title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                      className="surveyDropdowm"
                    >
                      <NavDropdown.Item href="#action/3.1" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Edit
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Duplicate
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </td>
              </tr>
              <tr>
                <td>
                  <Link to="/dashboard/SurveyTabs">Test Survey Title</Link>
                </td>
                <td>Testing Description</td>
                <td>Active</td>
                <td>09/05/2023</td>
                <td>35</td>
                <td>
                  <Nav>
                    <NavDropdown
                      title={<FaPlusSquare className="text-primary" style={{ boxShadow: '  -3px 3px' }} />}
                      className="surveyDropdowm"
                    >
                      <NavDropdown.Item href="#action/3.1" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Edit
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Duplicate
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3" className="dropDownItem">
                        <FaPen
                          style={{
                            background: 'linear-gradient(to top, rgb(17, 68, 138) 0%, rgb(23, 108, 217) 100%)',
                            padding: '5px',
                            borderRadius: '50%',
                            fill: '#fff',
                            fontSize: '20px',
                            marginRight: '5px',
                          }}
                        />{' '}
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <Row>
          <Col className="px-4 text-end mt-5 mb-4">
            <Link
              className="btn btn-primary"
              to="/dashboard/CreateSurvey"
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '13px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
              }}
            >
              Add New Survey
            </Link>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default SurveyTable;
