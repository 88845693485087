import React from 'react'
import { Col, Container, Row,Card } from 'react-bootstrap'
import WestEndClinicForm from '../../components/form/WestEndClinicForm'

const WestEndClinic = () => {
  return (
    <div>
      <Container>
        <Row className='justify-content-center'>
        <Col md={10}>
            <Card className=' shadow-sm px-5 pt-5 pb-3 ' border='0'>
                <Row>
                    <Col>
                        <h4 className='text-secondary'>West End Clinic</h4>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                      <WestEndClinicForm />
                    </Col>
                </Row>
            </Card>  
        </Col>
    </Row>
      </Container>
    </div>
  )
}

export default WestEndClinic