import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import { CardMedia, Button } from '@mui/material';

const PublishModal = (props) => {
  //   const [modalShow, setModalShow] = React.useState(false);

  return (
    <Modal {...props} size="sm" className="publishModal" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4 text-center">
        <div className="text-center">
          <h2 className="mb-4" style={{ fontSize: '38px', color: '#505050' }}>
            Are you sure you want to publish this survey?
          </h2>
          <p>Once published, the survey will be available in the survey library for all clinic directors.</p>
          <p>Clinic directors will be able to access and use this survey with their patients.</p>
          <p>Please review the survey questions and settings to ensure everything is correct before publishing.</p>
        </div>
        <Row className="mt-4">
          <Col className="text-center">
            <Button
              onClick={props.onHide}
              className="btn btn-secondary me-3"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                padding: '6px 25px',
                border: 'none',
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '6px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                color: '#fff',
              }}
            >
              Yes, Publish
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PublishModal;
