import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import UserCreationForm from '../../components/form/UserCreationForm'

const UserCreation = () => {
  return (
    <div>
        <Container>
            <Row className='justify-content-center'>
                <Col md={10}>
                    <Card className=' shadow-sm px-3 py-4' border='0'>
                        <h3 className='text-secondary mb-4'>User Creation</h3>

                        <UserCreationForm />
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default UserCreation
