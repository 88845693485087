import { Col, Container, Row,Card , Modal, Button, Badge} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FaPen, FaTrash, FaUser } from 'react-icons/fa';

const DirectorTable = () => {
  return (
    <div>
      <Table striped="rows" className='clinic-table director-table'>
      <thead>
        <tr>
          <th>Directors Name</th>
          <th>Clinic</th>
          <th colSpan={2}>Status</th>
        </tr>
      </thead>
      <tbody>

        <tr>
          <td>John Smith</td>
          <td>Cleveland Clinic</td>
          <td>Active</td>
          <td>
          <Link to='/dashboard/DirectorEdit' style={{textDecoration:'none',color:'#000'}}>
             <FaPen className='bg-primary text-light p-2  rounded-circle' 
                    type='button' 
                    style={{width:'29px',height:'29px', 
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >
                 &nbsp;
                Edit
             </Link>
             <FaTrash  type='button' className='text-light p-2 rounded-circle bg-secondary ms-3' style={{width:'29px',height:'29px'}}/ > &nbsp; <span className='text-dark'>
                 Delete</span>
            </td>
        </tr>
        <tr>
          <td>James</td>
          <td>Health Care</td>
          <td>Active</td>
          <td>
          <Link to='/dashboard/DirectorEdit' style={{textDecoration:'none',color:'#000'}}>
             <FaPen className='bg-primary text-light p-2  rounded-circle' 
                    type='button' 
                    style={{width:'29px',height:'29px', 
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >
                 &nbsp;
                Edit
             </Link>
             <FaTrash  type='button' className='text-light p-2 rounded-circle bg-secondary ms-3' style={{width:'29px',height:'29px'}}/ > &nbsp; <span className='text-dark'>
                 Delete</span>
            </td>
        </tr>

        <tr>
          <td>David</td>
          <td>Walk-in Care</td>
          <td>Active</td>
          <td>
          <Link to='/dashboard/DirectorEdit' style={{textDecoration:'none',color:'#000'}}>
             <FaPen className='bg-primary text-light p-2  rounded-circle' 
                    type='button' 
                    style={{width:'29px',height:'29px', 
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >
                 &nbsp;
                Edit
             </Link>
             <FaTrash  type='button' className='text-light p-2 rounded-circle bg-secondary ms-3' style={{width:'29px',height:'29px'}}/ > &nbsp; <span className='text-dark'>
                 Delete</span>
            </td>
        </tr>
        <tr>
          <td>Michels</td>
          <td>Mayo Clinic</td>
          <td>Access Disabled</td>
          <td>
          <Link to='/dashboard/DirectorEdit' style={{textDecoration:'none',color:'#000'}}>
             <FaPen className='bg-primary text-light p-2  rounded-circle' 
                    type='button' 
                    style={{width:'29px',height:'29px', 
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >
                 &nbsp;
                Edit
             </Link>
             <FaTrash  type='button' className='text-light p-2 rounded-circle bg-secondary ms-3' style={{width:'29px',height:'29px'}}/ > &nbsp; <span className='text-dark'>
                 Delete</span>
            </td>
        </tr>
        <tr>
          <td>Robert Brown</td>
          <td>Mayo Clinic</td>
          <td>Invitation Sent</td>
          <td>
          <Link to='/dashboard/DirectorEdit' style={{textDecoration:'none',color:'#000'}}>
             <FaPen className='bg-primary text-light p-2  rounded-circle' 
                    type='button' 
                    style={{width:'29px',height:'29px', 
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >
                 &nbsp;
                Edit
             </Link>
             <FaTrash  type='button' className='text-light p-2 rounded-circle bg-secondary ms-3' style={{width:'29px',height:'29px'}}/ > &nbsp; <span className='text-dark'>
                 Delete</span>
            </td>
        </tr>
        <tr>
          <td>Taylor Rachel</td>
          <td>Cleveland Clinic</td>
          <td>Invitation Sent</td>
          <td>
          <Link to='/dashboard/DirectorEdit' style={{textDecoration:'none',color:'#000'}}>
             <FaPen className='bg-primary text-light p-2  rounded-circle' 
                    type='button' 
                    style={{width:'29px',height:'29px', 
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >
                 &nbsp;
                Edit
             </Link>
             <FaTrash  type='button' className='text-light p-2 rounded-circle bg-secondary ms-3' style={{width:'29px',height:'29px'}}/ > &nbsp; <span className='text-dark'>
                 Delete</span>
            </td>
        </tr>

       
      </tbody>
    </Table> 
    </div>
  )
}

export default DirectorTable
