import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';

const TrueOrFalse = () => {

  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
          <Form className='radio-tabs'>
            <div className='d-flex align-items-center mt-4'>
              <Form.Check
                type="radio"
                name="frequency"
                checked={selectedOption === 'onceAWeek'}
                onChange={handleRadioChange}
              />
              <input type="text" className='ps-3 w-100'  placeholder='True' id="textInput" style={{height:'30px',width:'100%',border:'1px solid #F3F3F3'}} />

              <FaPlusCircle type='button' className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}} />
              <FaMinusCircle type='button'  className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}}/>
            </div>
            <div className='d-flex align-items-center mt-4'>
              <Form.Check
                type="radio"
                name="frequency"
                checked={selectedOption === 'onceAWeek'}
                onChange={handleRadioChange}
              />
              <input type="text" className='ps-3 w-100'  placeholder='False' id="textInput" style={{height:'30px',width:'100%',border:'1px solid #F3F3F3'}} />

              <FaPlusCircle type='button' className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}} />
              <FaMinusCircle type='button'  className='ms-3' style={{width:'25px', height:'25px',color:'#C3C3C3'}}/>
            </div>
     
    </Form>
    </div>
  )
}

export default TrueOrFalse
