import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

function MyRadioButtons({ label1, label2, label3, hiddenClass }) {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Form className="radio-tabs">
      <Form.Check
        type="radio"
        label={label1}
        name="frequency"
        className="mb-3"
        value="onceAWeek"
        checked={selectedOption === 'onceAWeek'}
        onChange={handleRadioChange}
      />
      <Form.Check
        type="radio"
        label={label2}
        name="frequency"
        className="mb-3"
        value="dailyBasis"
        checked={selectedOption === 'dailyBasis'}
        onChange={handleRadioChange}
      />
      <Form.Check
        type="radio"
        label={label3}
        name="frequency"
        className={`${hiddenClass} mb3`}
        value="twiceADay"
        checked={selectedOption === 'twiceADay'}
        onChange={handleRadioChange}
      />
    </Form>
  );
}

export default MyRadioButtons;
