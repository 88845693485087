import Accordion from 'react-bootstrap/Accordion';
import Select6 from './accordionselect/Select6';

function UserAccordionThree() {
  return (
    <Accordion defaultActiveKey="0" style={{border:'0'}} >
      <Accordion.Item eventKey="0" style={{border:'0'}}>
        <Accordion.Header><h5 className='text-muted'>System Configuration</h5></Accordion.Header>
        <Accordion.Body>
            <Select6 />
        </Accordion.Body>
      </Accordion.Item>
      
    </Accordion>
  );
}

export default UserAccordionThree;



