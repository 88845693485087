import React, {useState, useEffect } from "react";
import Chart from "react-apexcharts";

function Barchart1() {


    const gradientColors = {
        stops: [0, 100],
        gradientColor: ["#FF0000", "#0000FF"], // Red to blue gradient
      };


//   const [socialName, setsocialName]= useState([]);
//   const [socialValue, setSocialValue]= useState([]);

//   useEffect( ()=>{

//     const socialMedianame=[];
//     const socialMedivalue=[];

//     const getSocialrecord= async()=>{
//       const dataReq= await fetch("http://localhost/reactgraphtutorial/socialmedia");
//       const dataRes= await dataReq.json();
//       //console.log(dataRes);

//       for(let i=0; i<dataRes.length; i++)
//       {
//         socialMedianame.push(dataRes[i].socialmedia_title);
//         socialMedivalue.push(dataRes[i].socialmedia_subscriber);

//       }
//       setsocialName(socialMedianame);
//       setSocialValue(socialMedivalue);
//  }
//   getSocialrecord();

//   },[]);
  
  return (
    <>
      <div className="container px-0 mx-0">
        <Chart
          type="bar"
          style={{width:'100%'}}
          height={300}
          series={[
            {
              name: "Social Media Subscriber",
              data: [34,20,43,56,90,88,34],
            },
          ]}
          options={{
            fill: {
                type: 'gradient',
                gradient: gradientColors,
              },

            xaxis: {
              categories: ["Mon","Tue","Wed","Thur","Fri","Sat","Sun"],
            },

            yaxis: {
                labels: {
                  formatter: (val) => {
                  return `${val}`;
                  },
                style: { fontSize: "13", colors: ["#000"] },
              },
            },

            dataLabels: {
              formatter: (val) => {
                return `${val}`;
              },
              style: {
                colors: ["transparent"],
                fontSize: 15,
              },
            },

            plotOptions: {
                bar: {
                  borderRadius: 3,
                },
              },
          }}
        />
      </div>
    </>
  );
}

export default Barchart1;