import React from 'react';
import { Col, Container, Row, Card, Tabs, Tab } from 'react-bootstrap';
import ClinicDirectForm from '../../components/form/ClinicDirectForm';
import DcRobForm from '../../components/form/DcRobForm';

const EditClinic = () => {
  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <Card className=" shadow-sm px-5 pt-5 pb-3 " border="0">
              <Row>
                <Col>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h4 className="text-secondary">DC Rob</h4>
                    <span
                      style={{
                        display: 'inline-block',
                        color: '#FF0000',
                        backgroundColor: '#FF00001C',
                        borderRadius: '25px',
                        padding: '5px 10px',
                        fontSize: '10px',
                        marginLeft: '10px',
                      }}
                    >
                      Disabled
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Row>
                    <Col md={12}>
                      <div className="formTabs">
                        <Tabs
                          defaultActiveKey="clinicForm"
                          transition={false}
                          id="noanim-tab-example"
                          className="mb-3 mx-3 bg-white border-muted tab"
                          style={{
                            borderTop: '1px solid #ccc',
                            borderLeft: '1px solid #ccc',
                            borderRight: '1px solid #ccc',
                            borderBottom: '1px solid #ccc',
                          }}
                        >
                          <Tab eventKey="clinicForm" title={<span> Clinic Details</span>}>
                            <DcRobForm />
                          </Tab>
                          <Tab eventKey="directorForm" title={<span> Director Details</span>}>
                            <ClinicDirectForm />
                          </Tab>
                        </Tabs>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditClinic;
