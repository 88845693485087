import React from 'react';
import { Row, Col, Modal, Table } from 'react-bootstrap';
import { Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

const AnswerModal = (props) => {
  const [hidePassword, setHidePassword] = React.useState(false);

  const handleHidePassword = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered className="answerModal">
      <Modal.Body className="p-4 text-center">
        <Row className="mt-4">
          <Col style={{ textAlign: 'right' }}>
            <Button onClick={props.onHide} className="btn btn-outline-secondary me-3">
              Cancel
            </Button>
            <Button
              className="btn"
              style={{
                background: '#6A6A6A',
                border: 'none',
                borderRadius: '6px',
                fontSize: '0.875rem',
                color: '#fff',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
              }}
            >
              <PrintIcon /> Print
            </Button>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col xs={12}>
            <div className="answerTableWrp">
              <Table striped="rows" className="text-center">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Results</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>What is your name</td>
                    <td>N/A (This is qualitative data)</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AnswerModal;
