import React from 'react'
import { Container, Row ,Col,Card} from 'react-bootstrap'
import ButtonCustomExample from '../buttons/QuestionTabButton'
import QuestionsTabTable from '../tables/QuestionsTabTable'

const QuestionsTab = () => {
  return (
    <div>
      <Container>
        <Row>
            <Col>
                <Card className=' shadow-sm px-4 pt-4 pb-3 pe-4' border='0'>
                    <h4 className='text-secondary mb-4'>Survey-Patient Admission Assesment</h4>

                    <QuestionsTabTable/>
                
                    <Row className='justify-content-center text-cenetr mt-4'>
                        <Col md={3} className='text-center'>
                            <ButtonCustomExample />
                        </Col>
                    </Row>
                    
                </Card>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default QuestionsTab
