import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Age from './Age';
import Gender from './Gender';
import Clinic from './clinic';
import Clinician from './Clinician';
import PrimarySubstance from './PrimarySubstance'
import RaceEthnicity from './RaceEthnicity';
import InjectionDrug from './InjectionDrug';
import Time from './Time';

function AditionalParameter() {
  const [selectedOption, setSelectedOption] = useState('');
  const [visibleComponents, setVisibleComponents] = useState([]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Determine which components to show based on the selected option
    switch (selectedValue) {
      case 'option1':
            setVisibleComponents([<Clinic key="1" />]);
            break;
      case 'option2':
            setVisibleComponents([<Clinician key="2" />]);
            break;
      case 'option3':
            setVisibleComponents([<Age key="3" />]);
            break;
      case 'option4':
            setVisibleComponents([<Gender key="4" />]);
            break;
      case 'option5':
            setVisibleComponents([<PrimarySubstance key="5" />]);
            break;
      case 'option6':
              setVisibleComponents([<RaceEthnicity key="6" />]);
              break;
      case 'option7':
            setVisibleComponents([<InjectionDrug key="7" />]);
            break;
      case 'option8':
            setVisibleComponents([<Time key="8" />]);
            break;
        
      default:
        setVisibleComponents([]);
    }
  };

  return (
    <Container>
        <Row className='ps-0' style={{position:'relative'}}>
            <Col md={7} className='ps-0 ms-0' >
            <select onChange={handleSelectChange} value={selectedOption} className='w-100 ms-0 px-2 ' style={{height:'40px',border:'1px solid #DEE2E6',borderRadius:'5px', color:'#777'}}>
                <option value="">Select Filter</option>
                <option value="option1">Clinic</option>
                <option value="option2">Clinician</option>
                <option value="option3">Age</option>
                <option value="option4">Gender</option>
                <option value="option5">Primary Substance</option>
                <option value="option6">Race/ethnicity</option>
                <option value="option7">Injection drug use vs no drug use</option>
                <option value="option8">Time</option>
            </select>
            </Col>
            <Col md={5} style={{position:'absolute',right:'0'}}>
                {visibleComponents.map((component) => component)}
            </Col>
        </Row>
      



      
    </Container>
  );
}

export default AditionalParameter;
