import Accordion from 'react-bootstrap/Accordion';
import Select3 from './accordionselect/Select3';
import Select4 from './accordionselect/Select4';
import Select5 from './accordionselect/Select5';

function UserAccordionTwo() {
  return (
    <Accordion defaultActiveKey="0" style={{border:'0'}} >
      <Accordion.Item eventKey="0" style={{border:'0'}}>
        <Accordion.Header><h5 className='text-muted'>Licensee Management</h5></Accordion.Header>
        <Accordion.Body>
            <Select3 />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="0" style={{border:'0'}}>
        <Accordion.Header><h5 className='text-muted'>Outcome Measures</h5></Accordion.Header>
        <Accordion.Body>
          <Select4/>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="0" style={{border:'0'}}>
        <Accordion.Header><h5 className='text-muted'>Report Management</h5></Accordion.Header>
        <Accordion.Body>
          <Select5 />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default UserAccordionTwo;



