
// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { addDays, format } from 'date-fns';

// const DateRangeSelector = () => {
//   const [selectedDays, setSelectedDays] = useState(30);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);

//   const handleDaysChange = (e) => {
//     setSelectedDays(parseInt(e.target.value, 10));
//     setStartDate(null);
//     setEndDate(null);
//   };

//   return (
//     <div>
//       {/* <label>Select Days:</label> */}
//       <select value={selectedDays} onChange={handleDaysChange}>
//         <option value={30}>30 days</option>
//         <option value={20}>20 days</option>
//         <option value={15}>15 days</option>
//       </select>

//       {startDate && endDate && (
//         <div>
//           {/* <label>Selected Date Range:</label> */}
//           <div>
//             <input
//               type="text"
//               value={format(startDate, 'MM/dd/yyyy')}
//               readOnly
//             />
//             <span> to </span>
//             <input
//               type="text"
//               value={format(endDate, 'MM/dd/yyyy')}
//               readOnly
//             />
//           </div>
//         </div>
//       )}

//       {selectedDays > 1 && (
//         <div>
//           {/* <label>Select Date Range:</label> */}
//           <div>
//             nn
//             <DatePicker
          
//             selected={startDate}
//             startDate={startDate}
//             endDate={endDate}
//             onChange={(dates) => {
//               const [start, end] = dates;
//               setStartDate(start);
//               setEndDate(end);
//             }}
//             minDate={addDays(new Date(), -selectedDays + 1)}
//             maxDate={new Date()}
//             selectsRange
//             inline
//           />
//           </div>
          
//         </div>
//       )}
//     </div>
//   );
// };

// export default DateRangeSelector;


import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DateRangeSelector = ({ isOpen, onClose, onApply }) => {

  const [show, toggleShow] = useState(true);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleApply = () => {
    onApply(startDate, endDate);
    onClose();
  };

  return (
    <div className={`custom-date-picker  ${isOpen ? 'open' : ''}`}>

        {show && <Card className='hoverable-component date-pick px-2' border='0'>

        <div className='d-flex mt-4 justify-content-between align-items-center'>
        {/* <h5 style={{color:'#777', fontWeight:'900'}} className=''>Custom Date Range</h5> */}
                        <button onClick={() => toggleShow(!show)} className='text-secondary bg-white border-0'>x</button>
                    </div>
       
      <div className="date-fields d-flex justify-content-between mt-3">
        <div>
            <span style={{fontSize:'12px', fontWeight:'600',color:'#777'}}>To</span> <br/>
            <input
            type="text"
            placeholder="Start Date"
            value={startDate ? format(startDate, 'MM/dd/yyyy') : ''}
            readOnly
            style={{width:'120px',border:'1px solid #ccc'}}
            />
        </div>
        
        <div>
            <span style={{fontSize:'12px', fontWeight:'600',color:'#777'}}>From</span> <br/>
            <input
                type="text"
                placeholder="End Date"
                value={endDate ? format(endDate, 'MM/dd/yyyy') : ''}
                readOnly
                style={{width:'120px',border:'1px solid #ccc'}}
            />
        </div>
        
      </div>
      <span style={{fontSize:'12px', fontWeight:'600',color:'#777'}} className='mt-3'>Days Selected</span>
      <DatePicker
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={(dates) => {
          const [start, end] = dates;
          setStartDate(start);
          setEndDate(end);
        }}
        selectsRange
        inline
      />
      <Card.Footer className="buttons text-end bg-white" style={{borderTop:'1px solid #e6e6e6'}}>
        <Link style={{textDecoration:'none',fontSize:'13px',fontWeight:'700',marginRight:'10px'}} onClick={onClose}>Cancel</Link>
        <Link style={{textDecoration:'none',fontSize:'13px',fontWeight:'700'}} onClick={handleApply}>Apply</Link>
      </Card.Footer>


      </Card>}
    </div>

  );
};

export default DateRangeSelector;
