import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';

import MultipleChoiceOption from '../extra/MultipleChoiceOption';
import MultipleChoiceOptionB from '../extra/MultipleChoiceOptionB';
import SkipLogicMultipleQuestion from '../extra/SkipLogicMultipleQuestion';
import AddGadQuestion from '../extra/AddGadQuestion';
import StartRating from '../extra/StartRating';
import DateAndTime from '../extra/DateAndTime';
import ComponentG from '../extra/ComponentG';
import TrueOrFalse from '../extra/TrueOrFalse';
import ShortText from '../extra/ShortText';
import LongText from '../extra/LongText';
import UploadFile from '../extra/UploadFile';

const CreateQuestionsFields = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [visibleComponents, setVisibleComponents] = useState([]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    switch (selectedValue) {
      case 'option1':
        setVisibleComponents([<MultipleChoiceOption key="1" />]);
        break;
      case 'option2':
        setVisibleComponents([<MultipleChoiceOptionB key="2" />]);
        break;
      case 'option3':
        setVisibleComponents([<StartRating key="3" />]);
        break;
      case 'option4':
        setVisibleComponents([<DateAndTime key="4" />]);
        break;
      case 'option5':
        setVisibleComponents([<ComponentG key="5" />]);
        break;
      case 'option6':
        setVisibleComponents([<TrueOrFalse key="6" />]);
        break;
      case 'option7':
        setVisibleComponents([<ShortText key="7" />]);
        break;
      case 'option8':
        setVisibleComponents([<LongText key="8" />]);
        break;
      case 'option9':
        setVisibleComponents([<UploadFile key="9" />]);
        break;
      default:
        setVisibleComponents([]);
    }
  };

  return (
    <div>
      <Container>
        <Card className="px-2 pb-3 shadow-sm" border="0" style={{ background: '#F6F7F9' }}>
          <Row className="mt-4 justify-content-between">
            <Col sm={2} className="">
              <div className="pe-3">
                <input
                  type="text"
                  className="text-center pe-3"
                  value="Order"
                  id="textInput"
                  style={{ height: '40px', border: '1px solid #ccc' }}
                />
              </div>
            </Col>
            <Col sm={6} className="pe-0">
              <input
                type="text"
                className="text-center w-100"
                value="Enter your Question"
                id="textInput"
                style={{ height: '40px', width: '100%', border: '1px solid #ccc' }}
              />
            </Col>
            <Col sm={4} className="ps-0">
              <select
                onChange={handleSelectChange}
                value={selectedOption}
                className="w-100 ms-0"
                style={{ height: '40px', border: '1px solid #ccc' }}
              >
                {/* <option value="option">Option 1</option> */}
                <option value="option1">Numeric - discrete</option>
                <option value="option2">Numeric - continuous</option>
                <option value="option3">Star Rating</option>
                <option value="option4">Date/Time</option>
                <option value="option5">Drop Down</option>
                <option value="option6">True or false</option>
                <option value="option7">Short Text</option>
                <option value="option8">Long Text</option>
                <option value="option9">UploadFile</option>
              </select>
            </Col>
          </Row>
        </Card>

        <Row className="my-0">
          <Col>{visibleComponents.map((component) => component)}</Col>
        </Row>

        <Row className="mt-5">
          <Col className="text-end">
            <Link
              className="btn btn-outline-secondary me-3"
              to="/"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                padding: '5px 25px',
                fontSize: '13px',
              }}
            >
              Cancel
            </Link>
            <Button
              type="submit"
              variant="contained"
              className="text-light"
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '5px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateQuestionsFields;
