
import {useState} from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row, Card, Button } from 'react-bootstrap'


import ScoringAnswerValue from '../scoringcomponent/ScoringAnswerValue';
import ScoringAnswerDivide from '../scoringcomponent/ScoringAnswerDivide';
import ScoringImplement from '../scoringcomponent/ScoringImplement';
import ScoringValue from '../scoringcomponent/ScoringValue';
import SelectScoring from '../scoringcomponent/SelectScoring';


const ScoringOptionFields = () => {

    const [selectedOption, setSelectedOption] = useState('');
    const [visibleComponents, setVisibleComponents] = useState([]);
  
    const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedOption(selectedValue);



      switch (selectedValue) {
     
        case 'option1':
          setVisibleComponents([<ScoringValue key="1" />]);
          break;
        case 'option2':
          setVisibleComponents([<ScoringImplement key="2" />]);
          break;
        case 'option3':
          setVisibleComponents([<ScoringAnswerDivide key="3" />]);
          break;
        case 'option4':
          setVisibleComponents([<ScoringAnswerValue key="4" />]);
          break;
          case 'option0':
            setVisibleComponents([<SelectScoring key="6" />]);
            break;
        default:
          setVisibleComponents([]);
      }
    };



  return (
    <div>
      <Container>
        <Row>
        <Col sm={4} className='ps-3'>
                         <select onChange={handleSelectChange} value={selectedOption} className='w-100 ms-0 ps-2 text-secondary' style={{height:'40px',border:'1px solid #ccc'}}>
                            <option value="option0">Select Scoring</option>
                            <option value="option1">Values</option>
                            <option value="option2">% Implement</option>
                            <option value="option3">Answer Divide</option>
                            <option value="option4">Values Divide</option>
                        </select>
                </Col>
        </Row>
                

            <Row className='my-0'>
            
                <Col>
                    {visibleComponents.map((component) => component)}
                </Col>
            </Row>


            <Row className='mt-5'>
                <Col className='text-end'>
                    <Link className='btn btn-outline-secondary me-3' 
                            to='/' 
                            style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', 
                                     padding:'5px 25px',
                                    fontSize:'13px' }}>Cancel
                    </Link>
                    <Button 
                        type="submit" 
                        variant="contained"
                        className='text-light'
                        style={{background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)', 
                                padding:'5px 25px',
                                border:'none', 
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;'}}>Save   
                    </Button>  
                </Col>
            </Row>
      </Container>
    </div>
  )
}

export default ScoringOptionFields




