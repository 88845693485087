import React from 'react'
import { Col, Container, Row, Card, Form } from 'react-bootstrap'
import ScoringOptionFields from '../components/form/ScoringOptionFields'

const ScoringOption = () => {
  return (
    <div>
      <Container>
        <Row>
            <Col>
                <Card className=' shadow-sm px-2 py-3' border='0'>
                    <Row className='px-3'>
                        <Col md={6}>
                            <h4 className='fw-bold text-secondary'>Select Scoring Options</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ScoringOptionFields /> 
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ScoringOption
