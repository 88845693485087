import React from 'react';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ClinicTable from '../components/tables/ClinicTable';

const ClinicList = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className=" shadow-sm px-2 pt-4 pb-3 pe-4" border="0">
              <Row className="px-3">
                <Col>
                  <h4 className="text-secondary">Clinics</h4>
                </Col>
                <Col className="text-end">
                  <Link
                    className="btn btn-primary"
                    to="/dashboard/AddClinic"
                    style={{
                      background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                      border: 'none',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                      fontSize: '13px',
                    }}
                  >
                    Add New Clinic
                  </Link>
                </Col>
              </Row>

              <Row className='my-4 px-3 align-items-end'>
                <Col>
                <div className='leftFilter'>
                  <span>Status</span>
                  <select name="" id="">
                    <option value="">Option 1</option>
                  </select>
                </div>
                </Col>
                <Col>
                <div className='searchFilter'>
                  <input type="text" placeholder='Search' />
                </div>
                </Col>
              </Row>

              <Row>
                <Col className="mt-4">
                  <ClinicTable />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ClinicList;
