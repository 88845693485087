
import React from 'react'
import { Col, Container, Row ,Form} from 'react-bootstrap'
import {FaEnvelope, FaFile, FaFilePdf } from 'react-icons/fa'
import ColorDropdown from '../../components/color-dropdown/ColorDropdown'

const ChartTopHeading = () => {
  return (
    <div>
      <Container>
        <Row>
            <Col>
                <Row className='align-items-center'>
                    <Col md={5}>
                        <h1 style={{color:'#1973E9',fontSize:'45px' ,fontWeight:'900'}}>
                            Outcome Measure:
                        </h1>
                    </Col>

                    <Col md={4}>
                        <p>
                            Avg. percentage days the patients go without using any alcohol, marijuana, or other drug
                        </p>
                    </Col>

                    <Col md={3} className='text-end'>
                        <div className='d-flex align-items-center justify-content-around'>
                          <div>
                            <FaEnvelope className='text-primary me-2'/>
                            <small className='me-2' style={{fontSize:'12px',fontWeight:'500'}}> Email</small>
                          </div>
                          <div>
                            <FaFilePdf className='text-danger me-2'/>
                            <small className='me-2' style={{fontSize:'12px',fontWeight:'500'}}> PDF</small>
                          </div>
                         
                         
                          <ColorDropdown/>
                          </div>
                    </Col>
                </Row>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ChartTopHeading
