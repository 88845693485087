import { borderColor } from '@mui/system';
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaArrowAltCircleRight, FaArrowRight, FaCalendar, FaPlus } from 'react-icons/fa';
import { ArrowCircleRightOutlined } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import Person3Icon from '@mui/icons-material/Person3';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { Link } from 'react-router-dom';

const InfoCards = () => {
  return (
    <div>
      <Container className="mt-5">
        <Row>
          <Col md={3} className="mb-4">
            <Card className=" shadow-sm py-2 px-3" border="0" style={{ position: 'relative', borderRadius: '10px' }}>
              <div className="d-flex justify-content-between">
                <div>
                  <p
                    height={20}
                    className="text-center pt-1  rounded-2 bg-primary text-light"
                    style={{
                      position: 'absolute',
                      bottom: '70px',
                      height: '40px',
                      width: '40px',
                      background: 'linear-gradient(to top,#124C99  0%, #176edc 100%)',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                    }}
                  >
                    <MonitorHeartIcon sx={{ color: '#fff' }} />
                  </p>
                </div>
                <div className="text-right">
                  <h3 className="text-right text-secondary mb-0 pb-0" style={{ textAlign: 'right' }}>
                    106
                  </h3>
                  <p className="mt-0 pt-0" style={{ color: '#ccc' }}>
                    Patients
                  </p>
                </div>
              </div>
              <div
                style={{
                  height: '1px',
                  background:
                    'linear-gradient(to right,  transparent, transparent,#ccc,#ccc,  transparent, transparent)',
                }}
                className="mb-2"
              />
              <div className="d-flex align-items-center justify-content-between">
                <small className="text-secondary" style={{ fontSize: '10px', fontWeight: '600' }}>
                  No. of Patients
                </small>
              </div>
            </Card>
          </Col>

          <Col md={3} className="mb-4">
            <Card className=" shadow-sm py-2 px-3" border="0" style={{ position: 'relative', borderRadius: '10px' }}>
              <div className="d-flex justify-content-between">
                <div>
                  <p
                    height={20}
                    className="text-center pt-1  rounded-2 bg-primary text-light"
                    style={{
                      position: 'absolute',
                      bottom: '70px',
                      height: '40px',
                      width: '40px',
                      background: 'linear-gradient(to top,#124C99  0%, #176edc 100%)',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                    }}
                  >
                    <TroubleshootIcon sx={{ color: '#fff' }} />
                  </p>
                </div>
                <div className="text-right">
                  <h3 className="text-right text-secondary mb-0 pb-0" style={{ textAlign: 'right' }}>
                    2
                  </h3>
                  <p className="mt-0 pt-0" style={{ color: '#ccc' }}>
                    Clinicians
                  </p>
                </div>
              </div>
              <div
                style={{
                  height: '1px',
                  background:
                    'linear-gradient(to right,  transparent, transparent,#ccc,#ccc,  transparent, transparent)',
                }}
                className="mb-2"
              />
              <div className="d-flex align-items-center justify-content-between">
                <small className="text-secondary" style={{ fontSize: '10px', fontWeight: '600' }}>
                  No. of Clinicians
                </small>
              </div>
            </Card>
          </Col>

          <Col md={3} className="mb-4">
            <Link to="/dashboard/ClinicList">
              <Card className=" shadow-sm py-2 px-3" border="0" style={{ position: 'relative', borderRadius: '10px' }}>
                <div className="d-flex justify-content-between">
                  <div>
                    <p
                      height={20}
                      className="text-center pt-1  rounded-2 bg-primary text-light"
                      style={{
                        position: 'absolute',
                        bottom: '70px',
                        height: '40px',
                        width: '40px',
                        background: 'linear-gradient(to top,#124C99  0%, #176edc 100%)',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                      }}
                    >
                      <LocationCityIcon sx={{ color: '#fff' }} />
                    </p>
                  </div>
                  <div className="text-right">
                    <h3 className="text-right text-secondary mb-0 pb-0" style={{ textAlign: 'right' }}>
                      3
                    </h3>
                    <p className="mt-0 pt-0" style={{ color: '#ccc' }}>
                      Clinics
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: '1px',
                    background:
                      'linear-gradient(to right,  transparent, transparent,#ccc,#ccc,  transparent, transparent)',
                  }}
                  className="mb-2"
                />
                <div className="d-flex align-items-center justify-content-between">
                  <small className="text-secondary" style={{ fontSize: '10px', fontWeight: '600' }}>
                    View Clinics
                  </small>
                  <ArrowCircleRightOutlined sx={{ color: '#1A73E8', fontSize: '16px' }} />
                </div>
              </Card>
            </Link>
          </Col>
          <Col md={3} className="mb-4">
            <Link to="/dashboard/OutcomeMeasureCreateTable">
              <Card className=" shadow-sm py-2 px-3" border="0" style={{ position: 'relative', borderRadius: '10px' }}>
                <div className="d-flex justify-content-between">
                  <div>
                    <p
                      height={20}
                      className="text-center pt-1  rounded-2 bg-primary text-light"
                      style={{
                        position: 'absolute',
                        bottom: '70px',
                        height: '40px',
                        width: '40px',
                        background: 'linear-gradient(to top,#124C99  0%, #176edc 100%)',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                      }}
                    >
                      <PendingActionsIcon sx={{ color: '#fff' }} />
                    </p>
                  </div>
                  <div className="text-right">
                    <h3 className="text-right text-secondary mb-0 pb-0" style={{ textAlign: 'right' }}>
                      106
                    </h3>
                    <p className="mt-0 pt-0" style={{ color: '#ccc' }}>
                      Surveys
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: '1px',
                    background:
                      'linear-gradient(to right,  transparent, transparent,#ccc,#ccc,  transparent, transparent)',
                  }}
                  className="mb-2"
                />
                <div className="d-flex align-items-center justify-content-between">
                  <small className="text-secondary" style={{ fontSize: '10px', fontWeight: '600' }}>
                    View Surveys
                  </small>
                  <ArrowCircleRightOutlined sx={{ color: '#1A73E8', fontSize: '16px' }} />
                </div>
              </Card>
            </Link>
          </Col>
          <Col md={3} className="mb-4">
            <Link to="/dashboard/UserList">
              <Card className=" shadow-sm py-2 px-3" border="0" style={{ position: 'relative', borderRadius: '10px' }}>
                <div className="d-flex justify-content-between">
                  <div>
                    <p
                      height={20}
                      className="text-center pt-1  rounded-2 bg-primary text-white"
                      style={{
                        position: 'absolute',
                        bottom: '70px',
                        height: '40px',
                        width: '40px',
                        background: 'linear-gradient(to top,#124C99  0%, #176edc 100%)',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                      }}
                    >
                      <GroupsIcon sx={{ color: '#fff' }} />
                    </p>
                  </div>
                  <div className="text-right">
                    <h3 className="text-right text-secondary mb-0 pb-0" style={{ textAlign: 'right' }}>
                      1
                    </h3>
                    <p className="mt-0 pt-0" style={{ color: '#ccc' }}>
                      Staff
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: '1px',
                    background:
                      'linear-gradient(to right,  transparent, transparent,#ccc,#ccc,  transparent, transparent)',
                  }}
                  className="mb-2"
                />
                <div className="d-flex align-items-center justify-content-between">
                  <small className="text-secondary" style={{ fontSize: '10px', fontWeight: '600' }}>
                    View Staff
                  </small>
                  <ArrowCircleRightOutlined sx={{ color: '#1A73E8', fontSize: '16px' }} />
                </div>
              </Card>
            </Link>
          </Col>

          <Col md={3} className="mb-4">
            <Link to="/dashboard/Director">
              <Card className=" shadow-sm py-2 px-3" border="0" style={{ position: 'relative', borderRadius: '10px' }}>
                <div className="d-flex justify-content-between">
                  <div>
                    <p
                      height={20}
                      className="text-center pt-1  rounded-2 bg-primary text-light"
                      style={{
                        position: 'absolute',
                        bottom: '70px',
                        height: '40px',
                        width: '40px',
                        background: 'linear-gradient(to top,#124C99  0%, #176edc 100%)',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                      }}
                    >
                      <Person3Icon sx={{ color: '#fff' }} />
                    </p>
                  </div>
                  <div className="text-right">
                    <h3 className="text-right text-secondary mb-0 pb-0" style={{ textAlign: 'right' }}>
                      3
                    </h3>
                    <p className="mt-0 pt-0" style={{ color: '#ccc' }}>
                      Directors
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    height: '1px',
                    background:
                      'linear-gradient(to right,  transparent, transparent,#ccc,#ccc,  transparent, transparent)',
                  }}
                  className="mb-2"
                />
                <div className="d-flex align-items-center justify-content-between">
                  <small className="text-secondary" style={{ fontSize: '10px', fontWeight: '600' }}>
                    View Directors
                  </small>
                  <ArrowCircleRightOutlined sx={{ color: '#1A73E8', fontSize: '16px' }} />
                </div>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InfoCards;
