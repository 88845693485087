import { Container } from '@mui/material'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import ComparativeAnalysisForm from '../components/form/ComparativeAnalysisForm'

const ComparativeAnalysis = () => {
  return (
    <div>
        <Container>
            <Row>
                <Col>
                    <Card className=' shadow-sm px-3 pt-4' border='0'>
                        <Card.Body>
                            <h4 className='text-secondary'>
                                Comparative Analysis
                            </h4>
                            <Row>
                                <Col>
                                    <ComparativeAnalysisForm />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className='bg-white py-3' style={{borderColor:'#e6e6e6', color:'#ccc'}}>
                            Showing report for
                        </Card.Footer>
                        
                    </Card>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default ComparativeAnalysis
