import React from 'react';
import { Col, Container, Row, Tabs, Tab } from 'react-bootstrap';
import { FaChartBar, FaEye, FaLightbulb, FaTable } from 'react-icons/fa';
import MessageIcon from '@mui/icons-material/Message';
import PublishPreviewTab from './PublishPreviewTab';
import QuestionsTab from './QuestionsTab';
import AnswerTab from './AnswerTab';
import StatisticsTab from './StatisticsTab';
import TwoWayTab from './TwoWayTab';

const SurveyTabsData = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Tabs
              defaultActiveKey="question"
              transition={false}
              id="noanim-tab-example"
              className="mb-3 mx-3 bg-white border-muted tab"
              style={{
                borderTop: '1px solid #ccc',
                borderLeft: '1px solid #ccc',
                borderRight: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
              }}
            >
              <Tab
                eventKey="question"
                title={
                  <span>
                    <FaLightbulb /> Questions
                  </span>
                }
              >
                <QuestionsTab />
              </Tab>
              <Tab
                eventKey="profile"
                title={
                  <span>
                    <FaEye /> Preview & Publish
                  </span>
                }
              >
                <PublishPreviewTab />
              </Tab>
              <Tab
                eventKey="answers"
                title={
                  <span>
                    <MessageIcon /> Answers
                  </span>
                }
              >
                <AnswerTab />
              </Tab>
              <Tab
                eventKey="contact"
                title={
                  <span>
                    <FaChartBar /> Statistics
                  </span>
                }
              >
                <StatisticsTab />
              </Tab>
              <Tab
                eventKey="table"
                title={
                  <span>
                    <FaTable /> Two-way Tables
                  </span>
                }
              >
                <TwoWayTab />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SurveyTabsData;
