import React from 'react'
import {Dropdown} from 'react-bootstrap'
import {FaSquare } from 'react-icons/fa'


const ColorDropdown = () => {
  return (
    <div>
      <Dropdown className='py-0'>
        <Dropdown.Toggle id="dropdown-basic" variant='secondary' className='text-secondary py-0' style={{background:'#F2F2F2',border:'1px solid #ccc' ,borderRadius:'0px'}}>
            Color <span className='p-2' style={{color: '#1660C1'}}><FaSquare/></span>
        </Dropdown.Toggle>

        <Dropdown.Menu className='p-0'>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light bg-warning w-100 mb-0 py-2' style={{width:'100%',background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)'}}/></div></Dropdown.Item>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light bg-warning w-100 mb-0 py-2' style={{width:'100%',background:'#FB9A00'}}/></div></Dropdown.Item>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light w-100 mb-0 py-2' style={{width:'100%',background: '#FB9A00'}}/></div></Dropdown.Item>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light w-100 mb-0 py-2' style={{width:'100%',background: '#5381CF'}}/></div></Dropdown.Item>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light w-100 mb-0 py-2' style={{width:'100%',background: '#998CEB'}}/></div></Dropdown.Item>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light w-100 mb-0 py-2' style={{width:'100%',background: '#E9D787'}}/></div></Dropdown.Item>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light w-100 mb-0 py-2' style={{width:'100%',background: '#FF9A9F'}}/></div></Dropdown.Item>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light w-100 mb-0 py-2' style={{width:'100%',background: '#9EF5FF'}}/></div></Dropdown.Item>
            <Dropdown.Item className='py-0 mx-0'><div className='m-2 p-2 mx-0 shadow-sm' style={{background:'#F2F2F2'}}><p className='text-light w-100 mb-0 py-2' style={{width:'100%',background: '#BBE8AC'}}/></div></Dropdown.Item>
        </Dropdown.Menu>
     </Dropdown>
    </div>
  )
}

export default ColorDropdown
