import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import ComponentA from '../extra/MultipleChoiceOption';
import ComponentB from '../extra/MultipleChoiceOptionB';
import ComponentC from '../extra/SkipLogicMultipleQuestion';
import ComponentD from '../extra/AddGadQuestion';
import StartRating from '../extra/StartRating';
import ComponentF from '../extra/DateAndTime';
import ComponentG from '../extra/ComponentG';

function QuestionTypeSelect() {
  const [selectedOption, setSelectedOption] = useState('');
  const [visibleComponents, setVisibleComponents] = useState([]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Determine which components to show based on the selected option
    switch (selectedValue) {
      case 'option1':
        setVisibleComponents([<ComponentA key="1" />]);
        break;
      case 'option2':
        setVisibleComponents([<ComponentB key="2" />]);
        break;
      case 'option3':
        setVisibleComponents([<ComponentC key="3" />]);
        break;
      case 'option4':
        setVisibleComponents([<ComponentD key="4" />]);
        break;
      case 'option5':
        setVisibleComponents([<StartRating key="5" />]);
        break;
      case 'option6':
        setVisibleComponents([<ComponentF key="6" />]);
        break;
      case 'option7':
        setVisibleComponents([<ComponentG key="7" />]);
        break;
      default:
        setVisibleComponents([]);
    }
  };

  return (
    <Container>
      <select onChange={handleSelectChange} value={selectedOption} className='w-100 ms-0' style={{height:'40px',border:'1px solid #ccc'}}>
        <option value="">Short Text</option>
        <option value="option1">Option 1</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
        <option value="option4">Option 4</option>
        <option value="option5">Option 5</option>
        <option value="option6">Option 6</option>
        <option value="option7">Option 7</option>
      </select>

      {visibleComponents.map((component) => component)}
    </Container>
  );
}

export default QuestionTypeSelect;
