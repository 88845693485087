import {useState} from 'react'
import Multiselect from 'multiselect-react-dropdown'
import { Col, Container ,Row,Form} from 'react-bootstrap'
import { FaCalendar, FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
// import DateRangeSelector from './DateRangeSelector'
import AditionalParameter from '../comparativeanalysisselect.js/AditionalParameter'
import CustomCelender from '../customecelender/CostomCelender'


const ComparativeAnalysisForm = () => {

  const [show, toggleShow] = useState(true);

  const [food, setFood] = useState([
    "How much company environment is important",
    "Do you want a better future",
    "Write some article achieve the best health of life",
    "what is your ethnicity? Check all that apply",
    "what is your highest value of education?",
    "Do you consider yourself to be part of religious group",
    "During past 3 months,whould you say your health in general"
  ])
  const [fruit, setFruit] = useState(["red","blue","green"])


  return (
    <div>
      <Container className='mt-5'>
        <Row className='align-items-center mb-4'>
          <Col md={3} className='text-end' style={{fontSize:'13px', fontWeight:'500'}}><span>Outcome Measures<span className='text-danger'>*</span></span></Col>
          <Col md={5} className='pe-5'>
            <Multiselect
              style={{height:'40px',border:'1px solid #DEE2E6',borderRadius:'5px'}}
              placeholder='Select outcome measures'
              isObject={false}
              options={food}
              showCheckbox
            />
          </Col>
        </Row>
        <Row className='align-items-center mb-4'>
          <Col md={3} className='text-end' style={{fontSize:'13px', fontWeight:'500'}}><span>Report Type<span className='text-danger'>*</span></span></Col>
          <Col md={5} className='pe-5'>
            <Form.Select aria-label="" style={{ color:'#777'}}>
                <option value='option1' className='fw-bold' style={{fontWeight:'900', color:'#9a9a9a'}}>Comparative Analysis</option>
                <option value='option3'><FaCalendar /> Current Week </option>
                <option value='option4'>Current Month</option>
                <option value='option5'>Last Month</option>
                <option value='option6'>Custom Date Range</option>
              </Form.Select>
          </Col>
        </Row>

        <Row className='align-items-center mb-4'>
          <Col md={3} className='text-end' style={{fontSize:'13px', fontWeight:'500'}}><span>Compare Parameters<span className='text-danger'>*</span></span></Col>
          <Col md={5} className='pe-5'>
              <Form.Select aria-label="" style={{ color:'#777'}}>
                <option value='option1' className='fw-bold' style={{fontWeight:'900', color:'#9a9a9a'}}>All patients</option>
                <option value='option3'><FaCalendar /> All patients by age groups </option>
                <option value='option4'>All patients by genders</option>
                <option value='option5'>All patients by primary substances</option>
                <option value='option6'>All patients by races/ethnicities</option>
                <option value='option6'>All patients by injection drug use vs no drug use</option>
              </Form.Select>
          </Col>
        </Row>


        <Row className='align-items-center mb-4'>
          <Col md={3} className='text-end' style={{fontSize:'13px', fontWeight:'500'}}><span>Date Range</span></Col>
          <Col md={8} className=''>
            {/* <Form.Select aria-label="" style={{ color:'#777'}}>
              <option value='option1' className='fw-bold' style={{fontWeight:'900', color:'#9a9a9a'}}>Date Range</option>
              <option value='option3'><FaCalendar /> Current Week </option>
              <option value='option4'>Current Month</option>
              <option value='option5'>Last Month</option>
              <option value='option6'>Custom Date Range</option>
            </Form.Select> */}
            <CustomCelender />
          </Col>
        </Row>
      { show && <Row className='mb-4'>
          <Col md={3} className='text-end pt-2'  style={{fontSize:'13px', fontWeight:'500'}}><span>Aditional Parameters</span></Col>
          <Col md={8} className=''>
           <AditionalParameter />
          </Col>
        </Row>
    }
        <Row className='align-items-center mb-4'>
          <Col md={3} />
          <Col md={6} className='pe-4'>
         
         
          <button onClick={() => toggleShow(!show)} style={{color:'#165DBC',fontSize:'13px', fontWeight:'600'}} className='bg-white border-0'>{show ? <FaMinusCircle /> : <FaPlusCircle />} Comparasions</button>
          </Col>
        </Row>


        <Row className='align-items-center mb-4'>
          <Col md={3} className='text-end' style={{fontSize:'13px', fontWeight:'500'}}><span>Compare Parameters<span className='text-danger'>*</span></span></Col>
          <Col md={4}>
            <Form.Select aria-label="" style={{ color:'#777'}}>
                <option value='option1' className='fw-bold'>Chart Type</option>
                <option value='option2'>Bar</option>
                <option value='option3'>Pie Chart</option>
                <option value='option4'>Line Chart</option>
            </Form.Select>
          </Col>
        </Row>
        {/* <DateRangeSelector /> */}
        <Row className='align-items-center mb-4'>
          <Col md={3} />
          <Col md={6} className='pe-4'>
            <Row className='mt-3'>
              <Col>
               
                <Link 
                      type="submit" 
                      variant="contained"
                      to='/dashboard/OutcomeMeasureTable' 
                      className='text-light btn me-3'
                      style={{background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)', 
                              padding:'10px 25px',
                              border:'none', 
                              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;'}}>Generate Report
                  </Link>  

                  <Link className='btn btn-outline-secondary' 
                      to='/dashboard/OutcomeMeasureTable' 
                      style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', 
                                padding:'10px 25px',
                                fontSize:'13px' }}>Reset Filters
                  </Link>

              </Col>
            </Row>

            
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default ComparativeAnalysisForm
