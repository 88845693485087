import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography,Card,CardHeader } from '@mui/material';
// components


import { faker } from '@faker-js/faker';
import { useTheme } from '@mui/material/styles';
import { LineChart,PieChart ,BarChart} from '@mui/x-charts';
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
// mock


// ----------------------------------------------------------------------
const pData = [10, 15, 12, 18, 20, 25, 30];
const uData = [100, 120, 90, 110, 130, 150, 140];
const xLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function BlogPage() {
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Dashboard: Blog | Minimal UI </title>
      </Helmet>

      <Container>
      <Grid container spacing={3}>
          


          <Grid item xs={12} md={6} lg={6}>
            <Card>

            <CardHeader title={'Outcome Measure'} subheader={'Avg. percentage days the patients go without using any alcohol, marijuana, or other drug, '} />

          <LineChart
      width={500}
      height={300}
      series={[
        { data: pData, yAxisKey: 'leftAxisId' },
      //  { data: uData, label: 'uv', yAxisKey: 'rightAxisId' },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
      rightAxis="rightAxisId"
    />
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:5}}>
    Surveys Completed 
    </Typography>
    <Typography sx={{fontSize:12,ml:5,mb:2}}>
    This week
    </Typography>
      </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card>

            <CardHeader title={'Outcome Measure'} subheader={'Avg. percentage days the patients go without using any alcohol, marijuana, or other drug, '} />

          <LineChart
      width={500}
      height={300}
      series={[
        { data: pData, yAxisKey: 'leftAxisId' },
      //  { data: uData, label: 'uv', yAxisKey: 'rightAxisId' },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
      rightAxis="rightAxisId"
    />
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:5}}>
    Surveys Completed 
    </Typography>
    <Typography sx={{fontSize:12,ml:5,mb:2}}>
    This week
    </Typography>
      </Card>

          </Grid>
  <Grid item xs={12} md={6} lg={6}>
            <Card>

            <CardHeader title={'Outcome Measure'} subheader={'Avg. percentage days the patients go without using any alcohol, marijuana, or other drug, '} />

            <PieChart
  series={[
    {
      data: [
        { id: 0, value: 10, label: 'series A' },
        { id: 1, value: 15, label: 'series B' },
        { id: 2, value: 20, label: 'series C' },
      ],
    },
  ]}
  width={400}
  height={200}
/>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:5}}>
    Surveys Completed 
    </Typography>
    <Typography sx={{fontSize:12,ml:5,mb:2}}>
    This week
    </Typography>
      </Card>
      
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card>

            <CardHeader title={'Outcome Measure'} subheader={'Avg. percentage days the patients go without using any alcohol, marijuana, or other drug, '} />

            <PieChart
  series={[
    {
      data: [
        { id: 0, value: 10, label: 'series A' },
        { id: 1, value: 15, label: 'series B' },
        { id: 2, value: 20, label: 'series C' },
      ],
    },
  ]}
  width={400}
  height={200}
/>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:5}}>
    Surveys Completed 
    </Typography>
    <Typography sx={{fontSize:12,ml:5,mb:2}}>
    This week
    </Typography>
      </Card>
      
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card>

            <CardHeader title={'Outcome Measure'} subheader={'Avg. percentage days the patients go without using any alcohol, marijuana, or other drug, '} />

            <BarChart
  xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
  series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
  width={500}
  height={300}
/>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:5}}>
    Surveys Completed 
    </Typography>
    <Typography sx={{fontSize:12,ml:5,mb:2}}>
    This week
    </Typography>
      </Card>
      
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card>

            <CardHeader title={'Outcome Measure'} subheader={'Avg. percentage days the patients go without using any alcohol, marijuana, or other drug, '} />

            <BarChart
  xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
  series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
  width={500}
  height={300}
/>
    <Typography sx={{fontSize:14,fontWeight:'bold',ml:5}}>
    Surveys Completed 
    </Typography>
    <Typography sx={{fontSize:12,ml:5,mb:2}}>
    This week
    </Typography>
      </Card>
      
          </Grid>
         
        </Grid>      </Container>
    </>
  );
}
