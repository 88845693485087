import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import UserAccordionOne from '../../components/accordion/UserAccordionOne';
import UserAccordionTwo from '../../components/accordion/UserAccordionTwo';
import UserAccordionThree from '../../components/accordion/UserAccordionThree';

const UserPermissions = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className="shadow-sm px-3 py-5" border="0">
              <h3 className="text-secondary mb-3">User Role</h3>

              <Row>
                <Col md={4} className="pe-5">
                  <UserAccordionOne />
                </Col>
                <Col md={4} className="pe-5">
                  <UserAccordionTwo />
                </Col>
                <Col md={4} className="pe-5">
                  <UserAccordionThree />
                </Col>
              </Row>
              <Row>
          <Col>
            <button
              className="btn btn-outline-secondary me-3"
              type='button'
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                padding: '13px 25px',
                fontSize: '13px',
              }}
            >
              Cancel
            </button>
          </Col>
          <Col>
          <div className='text-end'>
          <button
            type="button"
            className='btn'
            style={{
              background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
              padding: '13px 25px',
              border: 'none',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
              color: '#fff'
            }}
          >
            Save
          </button>
          </div>
          </Col>
        </Row>
            </Card>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
};

export default UserPermissions;
