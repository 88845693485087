import React from 'react'
import { Container, Row,Col, Form, FloatingLabel, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NumberField from './NumberField'

const CreateSurveyForm = () => {
  return (
    <div>
      
      <Container className='py-5 pe-5'>
        <Row >
            <Col md={12}>
                <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label className='text-muted'>Survey Title</Form.Label>
                <Form.Control type="text" placeholder="" />
                </Form.Group>
            </Col>

            <Col md={12} className='mt-3'>
                <Form.Label className='text-muted'>Description</Form.Label>
                <FloatingLabel controlId="floatingTextarea2">
                    <Form.Control
                    as="textarea"
                    style={{ height: '100px' }}
                    />
                </FloatingLabel>
            </Col>

            <Col md={12} className='mt-3'>
                <div className='d-flex align-items-center'>
                    <NumberField  className='me-4'/>
                    <small style={{fontSize:'12px', fontWeight:'500px' ,marginLeft:'20px'}}>Max no. of time a person can take per day</small>
                </div>

                <div className='d-flex align-items-center mt-2'>
                    <NumberField className='me-4' />
                    <small style={{fontSize:'12px', fontWeight:'500px',marginLeft:'20px'}}>Max no. of time a person can take in total</small>
                </div>
            </Col>
        </Row>

        <Row className='justify-content-between mt-5'>
            <Col sm={8}>
            <Form.Check aria-label="option 1"  label="Email me when a survey is taken"/>
            </Col>
            <Col sm={4} className='text-end'>
            <Link className='btn btn-primary' to='/dashboard/SurveyTabs' style={{background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)', border:'none', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', fontSize:'13px' }}>Save</Link>
            </Col>
        </Row>
      </Container>

    </div>
  )
}

export default CreateSurveyForm
