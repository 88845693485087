import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import emojiImg from '../../assets/images/emoji.png';

const StartRating = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Form className="radio-tabs">
        <div className="d-flex align-items-center mt-4">
          <input
            type="text"
            className="ps-3 w-100"
            placeholder="Enter rating label here"
            id="textInput"
            style={{ height: '30px', width: '100%', border: '1px solid #F3F3F3' }}
          />

          <FaPlusCircle type="button" className="ms-3" style={{ width: '25px', height: '25px', color: '#C3C3C3' }} />
          <FaMinusCircle type="button" className="ms-3" style={{ width: '25px', height: '25px', color: '#C3C3C3' }} />
        </div>
        <Row>
          <Col xs={3}>
            <div className="selectBox">
              <select name="" id="">
                <option value="">5</option>
              </select>
            </div>
          </Col>
          <Col xs={3}>
            <div className="selectBox">
              <select name="" id="">
                <option value="">Smiley</option>
                <option value="">Heart</option>
                <option value="">Thumbsup</option>
                <option value="">Star</option>
              </select>
            </div>
          </Col>
          <Col xs={4}>
            <div className="emojiImg">
              <img src={emojiImg} alt="" />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default StartRating;
