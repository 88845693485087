import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import LaunchIcon from '@mui/icons-material/Launch';
import DirectorEditForm from '../../components/form/DirectorEditForm';

const DirectorEdit = () => {
  return (
    <div>
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <Card className=" shadow-sm px-5 pt-5 pb-3 " border="0">
              <Row>
                <Col>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h2 className="text-secondary">Dr Ruth Parker</h2>
                      <span
                        style={{
                          display: 'inline-block',
                          color: '#04c104',
                          backgroundColor: '#04c10424',
                          borderRadius: '25px',
                          padding: '5px 10px',
                          fontSize: '10px',
                          marginLeft: '10px',
                        }}
                      >
                        Active
                      </span>
                    </div>
                    <button
                      type="button"
                      style={{ backgroundColor: 'transparent', border: 'none', padding: '0', color: '#495057', fontSize: '13px' }}
                    >
                      User Logs <LaunchIcon sx={{ fontSize: 13 }} />
                    </button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <DirectorEditForm />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DirectorEdit;
