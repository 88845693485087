import  {useState} from 'react'
import { Col, Container, Row ,Form} from 'react-bootstrap'
import { FaToolbox } from 'react-icons/fa'
import PieChart1 from '../components/charts/PieChart'
import PieChart2 from '../components/charts/PieChart2'
import ChartBottomHeading from './chart/ChartBottomHeading'
import ChartTopHeading from './chart/ChartTopHeading'

const Charts = () => {

  const [show, toggleShow] = useState(true);
  const [show2, toggleShow2] = useState(true);
  const [show3, toggleShow3] = useState(true);


  return (
    <div>
      <Container>
        <Row>
          <Col md={8}>
            <div className='d-flex align-items-center mb-4'>
              <h6 className='text-muted  pt-2 me-5'>Showing report for</h6>
               {show &&<span className=' px-2 me-2 text-muted' style={{border:'1px solid #e6e6e6', fontSize:'13px'}}>Male <button onClick={() => toggleShow(!show)} className='text-warning bg-white border-0'>x</button></span>}
               {show2 &&<span className=' px-2 me-2 text-muted' style={{border:'1px solid #e6e6e6', fontSize:'13px'}}>Outcome Measures <button onClick={() => toggleShow2(!show2)} className='text-warning bg-white border-0'>x</button></span>}
               {show3 &&<span className=' px-2 me-2 text-muted' style={{border:'1px solid #e6e6e6', fontSize:'13px'}}>Race <button onClick={() => toggleShow3(!show3)} className='text-warning bg-white border-0'>x</button></span>}
            </div>
          </Col>
          <Col md={4}>
            <div className='d-flex align-items-center justify-content-around'>
              <span className='text-muted'  style={{fontSize:'13px'}}>Chart Type</span>
              <Form.Select aria-label="Default select example"  style={{width:'170px'}}>
                <option value="1">Pie Chart</option>
                <option value="2">Line Graph</option>
                <option value="3">Bar</option>
              </Form.Select>
              <FaToolbox className='p-2 me-3 rounded-2 text-light' style={{fontSize:'17px',background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',height:'38px',width:'38px'}}/>

              
            </div>
          </Col>

          </Row>
          <Row>
          <Col md={12} className='py-0 mb-3'>
                <ChartTopHeading/>
            </Col>
            <Col md={6} className='py-0 my-0'>
               <PieChart1 />
            </Col>
            <Col md={6} className='py-0 my-0'>
               <PieChart1 />
            </Col>
            <Col md={12} className='py-0 my-3'>
                <ChartBottomHeading/>
            </Col>
            <Col md={6} className='py-0 my-0'>
               <PieChart2 />
            </Col>
            <Col md={6} className='py-0 my-0'>
               <PieChart2 />
            </Col>
          </Row>
      </Container>
    </div>
  )
}

export default Charts
