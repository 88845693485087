import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  import { Line } from "react-chartjs-2";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  
  const options = {
    plugins: {
      legend: {
        position: "top",
        style:{display:'none'}
      },
    },
  };
  
  export const data = {
    labels,
    datasets: [
      {
        data: [10, 5, 25, 22, 41, 15,30],
        backgroundColor: "#1973E9",
        borderColor: "#1973E9",
      },
    ],
  };
  
  const LineChartExample1 = () => {
    return (
      <div style={{ width: '100%', height:250 }}>
        <Line options={options} data={data} />
      </div>
    );
  };
  
  export default LineChartExample1;