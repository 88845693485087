
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {FaEnvelope, FaFile, FaFilePdf } from 'react-icons/fa'

const ChartBottomHeading = () => {
  return (
    <div>
      <Container>
        <Row>
            <Col>
                <Row className='align-items-center'>
                    <Col md={5}>
                        <h1 style={{color:'#1973E9',fontSize:'45px' ,fontWeight:'900'}}>
                            Outcome Measure:
                        </h1>
                    </Col>

                    <Col md={4}>
                        <p>
                            Avg. percentage days the patients took prescription drugs to help with drug
                        </p>
                    </Col>

                    <Col md={3}/>
                </Row>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ChartBottomHeading
