import { useState } from 'react';
import { Link } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';

const CreateQuestionsFields = () => {
  return (
    <div>
      <Container>
        <Card className="px-2 pb-3 shadow-sm" border="0" style={{ background: '#F6F7F9' }}>
          <Row className="mt-4 justify-content-between">
            <Col sm={2} className="">
              <div className="pe-3">
                <input type="text" className="text-center pe-3" style={{ height: '40px', border: '1px solid #ccc' }} />
              </div>
            </Col>
            <Col sm={10} className="pe-0">
              <input
                type="text"
                // className="w-100"
                // value="Enter your Question"
                placeholder='What is your activity level?'
                // id="textInput"
                style={{ height: '40px', border: '1px solid #ccc', paddingLeft: '10px', width: '98%' }}
              />
            </Col>
          </Row>
        </Card>

        <Row className="my-0">
          <Col>
            <div className="addScoreTxt">
              <ul>
                <div className="addScoreHeading">
                  <span>
                    <MessageIcon /> Answer :
                  </span>
                </div>
                <li>Not active at all</li>
                <li>Somewhat active</li>
                <li>Active</li>
                <li>Hyper active</li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col className="text-end">
            <Link
              className="btn btn-outline-secondary me-3"
              to="/"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                padding: '5px 25px',
                fontSize: '13px',
              }}
            >
              Cancel
            </Link>
            <Button
              type="submit"
              variant="contained"
              className="text-light"
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '5px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
              }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateQuestionsFields;
