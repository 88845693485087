import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { FaEye, FaLock, FaLockOpen, FaPen, FaTrash, FaUser, FaUserEdit } from 'react-icons/fa';



const UserLogTable = () => {

   


  return (
    <div>
      <Table striped="rows" className='clinic-table user-list-table'>
      <thead>
        <tr className=''>
          <th>Date</th>  
          <th>Time</th>
          <th>IP</th>
          <th>Username</th>
          <th>Activity</th>
          <th>User Role</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>

        <tr>
            <td>07/20/2022</td>
            <td>08:00 AM</td>
            <td>00.05.78</td>
            <td>John Smith</td>
            <td>Login</td>
            <td>Manager</td>
            <td className='text-center d-flex justify-content-around' style={{right:'10px'}}>
                <div><FaEye className='bg-primary text-light p-1  rounded-circle' type='button' style={{width:'20px',height:'20px', background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >&nbsp;<span className='text-secondary' style={{fontSize:'12px'}}>Detail</span> </div>
            </td>
        </tr>

        <tr>
            <td>07/19/2022</td>
            <td>09:00 AM</td>
            <td>00.05.78</td>
            <td>Robert</td>
            <td>Ctrated Patient</td>
            <td>Manager</td>
            <td className='text-center d-flex justify-content-around' style={{right:'10px'}}>
                <div><FaEye className='bg-primary text-light p-1  rounded-circle' type='button' style={{width:'20px',height:'20px', background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >&nbsp;<span className='text-secondary' style={{fontSize:'12px'}}>Detail</span> </div>
            </td>
        </tr>


        <tr>
            <td>07/16/2022</td>
            <td>08:00 AM</td>
            <td>00.05.78</td>
            <td>Robert</td>
            <td>Crteated Apponitment</td>
            <td>coordinator</td>
            <td className='text-center d-flex justify-content-around' style={{right:'10px'}}>
                <div><FaEye className='bg-primary text-light p-1  rounded-circle' type='button' style={{width:'20px',height:'20px', background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >&nbsp;<span className='text-secondary' style={{fontSize:'12px'}}>Detail</span> </div>
            </td>
        </tr>

        <tr>
            <td>07/15/2022</td>
            <td>08:25 AM</td>
            <td>00.05.78</td>
            <td>Michael</td>
            <td>Logout</td>
            <td />
            <td className='text-center d-flex justify-content-around' style={{right:'10px'}}>
                <div><FaEye className='bg-primary text-light p-1  rounded-circle' type='button' style={{width:'20px',height:'20px', background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)'}}/ >&nbsp;<span className='text-secondary' style={{fontSize:'12px'}}>Detail</span> </div>
            </td>
        </tr>

        
       
      </tbody>
    </Table> 
    </div>
  )
}

export default UserLogTable
