import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Modal } from 'react-bootstrap';
import {  CardMedia, Button } from '@mui/material';

const DeleteModal = (props) => {
//   const [modalShow, setModalShow] = React.useState(false);

  return (
    <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4 text-center">
        <div className=" ps-5 text-center">
          <CardMedia
            component="img"
            sx={{ width: 'auto', height: 150, textAlign: 'center', ml: 2 }}
            image="/assets/illustrations/question.png"
            alt="Live from space album cover"
          />
        </div>
        <h4 className="text-muted text-center">{props.dltModalTxt}</h4>
        <Row className="mt-4">
          <Col className="text-center">
            <Button
              className="btn btn-secondary me-3"
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                padding: '6px 25px',
                border: 'none'
              }}
            >
              Delete
            </Button>
            <Button
              onClick={props.onHide}
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '6px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                color: '#fff',
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
