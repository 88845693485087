import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OutcomeMeasureCreateTableData from '../../components/tables/OutcomeMeasureCreateTableData';
import 'react-date-range/dist/styles.css'; // Import the default styles
import 'react-date-range/dist/theme/default.css'; // Import the default theme styles

const OutcomeMeasureCreateTable = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const handleDateSelect = (ranges) => {
    // Assuming you only need one date (e.g., start date)
    const selectedStartDate = ranges.selection.startDate;

    setSelectedDate(selectedStartDate);

    setShowCalendar(false);
  };

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };
  return (
    <div>
      <Container>
        <Row className="my-4 px-3 align-items-end">
          <Col className="d-flex align-items-center">
            <div className="leftFilter">
              <span className="filterLabel">Status</span>
              <select name="" id="">
                <option value="">Option 1</option>
              </select>
            </div>
            <div className="leftFilter ms-3">
              {/* <span>Date Range</span>
                    <input type="date" /> */}
              <span className="filterLabel">Date Range</span>
              <button onClick={handleCalendarToggle} className="calendarBtn">
                {/* {selectedDate ? selectedDate.toDateString() : 'Open Calendar'} */}
                <CalendarMonthIcon /> Last 30 Days
              </button>
              {showCalendar && (
                <DateRangePicker
                  ranges={[
                    {
                      startDate: selectedDate || new Date(),
                      endDate: selectedDate || new Date(),
                      key: 'selection',
                    },
                  ]}
                  onChange={handleDateSelect}
                />
              )}
            </div>
          </Col>
          <Col>
            <div className="searchFilter">
              <input type="text" placeholder="Search" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <OutcomeMeasureCreateTableData />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OutcomeMeasureCreateTable;
