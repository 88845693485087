import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { Button } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const PasswordResetModal = (props) => {
  const [hidePassword, setHidePassword] = React.useState(false);

  const handleHidePassword = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body className="p-4 text-center">
        <div className="text-center ">
          <h5 className="text-center primary mb-4">Reset Password</h5>
          <form action="" className="mt-4">
            <div className="position-relative">
              <input type={hidePassword ? 'text' : 'password'} style={{ width: '270px', padding: '5px' }} />
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: '0',
                  position: 'absolute',
                  right: '23%',
                  top: '15%',
                }}
                onClick={handleHidePassword}
              >
                {hidePassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
              </button>
            </div>
          </form>
        </div>
        <div className="passwordSpan mt-4">
          <ul>
            <li>
              <span className="crossMark">
                <CloseIcon /> Has atleast 8 characters?
              </span>
            </li>
            <li>
              <span className="crossMark">
                <CloseIcon /> Has one digit?
              </span>
            </li>
            <li>
              <span className="checkMark">
                <CheckIcon /> Has one letter
              </span>
            </li>
          </ul>
        </div>
        <Row className="mt-4">
          <Col className="text-center">
            <Button onClick={props.onHide} className="btn btn-outline-secondary me-3">
              Cancel
            </Button>
            <Button
              className="btn"
              style={{
                background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)',
                padding: '6px 25px',
                border: 'none',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;',
                color: '#fff',
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordResetModal;
