import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import DirectorTable from '../components/tables/DirectorTable';

const Director = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Card className=" shadow-sm px-4 pt-5 pb-3 " border="0">
              <h3 className="text-secondary">Directors</h3>

              <Row className="my-4 px-3 align-items-end">
                <Col>
                  <div className="leftFilter">
                    <span>Status</span>
                    <select name="" id="">
                      <option value="">Option 1</option>
                    </select>
                  </div>
                </Col>
                <Col>
                  <div className="searchFilter">
                    <input type="text" placeholder="Search" />
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col>
                  <DirectorTable />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Director;
