import React from 'react';
import { VictoryPie } from 'victory-pie';

const myData = [
    {x:"Asian 21%",y:400},
    {x:"American 26%",y:500},
    {x:"latin 0%",y:10},
    {x:"African 15%",y:300},
    {x:"Europian 15%",y:300},
];

function PieChart1() {
    return (
        <div style={{height:'200'}}>
            <VictoryPie 

            style={{
                data: {
                fillOpacity: 0.9, stroke: "#fff", strokeWidth: 2
                },
                labels: {
                fontSize: 14, fill: "#000",margin:0
                }
            }}
  
             data={myData}  
             colorScale={["#1761C3", "#FDA61D","#1761C3","#1761C3", "#FDA61D"]} 
             radius={150}  
            />
        </div>

);
}

export default PieChart1;