import { useState } from 'react';
import { Col, Container, Row, Card, Modal, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { FaLock, FaLockOpen, FaPen, FaTrash, FaUser, FaUserEdit } from 'react-icons/fa';
import ToggleButton from 'react-toggle-button';

const UserListTable = () => {
  const [value, setValue] = useState(false);

  const handleToggle = () => {
    setValue(!value);
  };

  return (
    <div>
      <Table striped="rows" className="clinic-table user-list-table">
        <thead>
          <tr className="">
            <th>Username</th>
            <th>Email</th>
            <th>User Created</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>John Smith</td>
            <td>johnsmith@gmail.com</td>
            <td>N/A</td>
            <td className="ps-3 justify-content-center text-center">
              <div className="d-flex justify-content-center align-items-center pt-1">
                <ToggleButton
                  icons={{
                    checked: 'test', // Customize the "on" text
                    unchecked: 'OFF', // Customize the "off" text
                  }}
                  value={value}
                  onToggle={handleToggle}
                />
              </div>
            </td>

            <td className="text-center d-flex justify-content-around" style={{ right: '10px' }}>
              {/* <div>
                <FaPen
                  className="bg-primary text-light p-2  rounded-circle"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Edit
                </span>{' '}
              </div> */}

              {/* <div>
                <FaLockOpen
                  className="text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, #A46808 0%, #ED9201 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Reset Password
                </span>{' '}
              </div> */}
              <Link to="/dashboard/UserPermissions">
                <div>
                  <FaUser
                    className="bg-primary text-light p-2  rounded-circle ms-2"
                    type="button"
                    style={{
                      width: '29px',
                      height: '29px',
                      background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                    }}
                  />
                  &nbsp;
                  <span className="text-secondary" style={{ fontSize: '12px' }}>
                    User Rights
                  </span>{' '}
                </div>
              </Link>
              <div>
                <FaTrash
                  type="button"
                  className="text-light p-2 rounded-circle bg-secondary ms-2"
                  style={{ width: '29px', height: '29px' }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Delete
                </span>
              </div>
              {/* <div>
                <FaUserEdit
                  className="bg-primary text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  User Log
                </span>{' '}
              </div> */}
            </td>
          </tr>
          <tr>
            <td>John Smith</td>
            <td>johnsmith@gmail.com</td>
            <td>N/A</td>
            <td className="ps-3 justify-content-center text-center">
              <div className="d-flex justify-content-center align-items-center pt-1">
                <ToggleButton value={value} onToggle={handleToggle} />
              </div>
            </td>

            <td className="text-center d-flex justify-content-around" style={{ right: '10px' }}>
              {/* <div>
                <FaPen
                  className="bg-primary text-light p-2  rounded-circle"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Edit
                </span>{' '}
              </div> */}

              {/* <div>
                <FaLockOpen
                  className="text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, #A46808 0%, #ED9201 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Reset Password
                </span>{' '}
              </div> */}
              <Link to="/dashboard/UserPermissions">
                <div>
                  <FaUser
                    className="bg-primary text-light p-2  rounded-circle ms-2"
                    type="button"
                    style={{
                      width: '29px',
                      height: '29px',
                      background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                    }}
                  />
                  &nbsp;
                  <span className="text-secondary" style={{ fontSize: '12px' }}>
                    User Rights
                  </span>{' '}
                </div>
              </Link>
              <div>
                <FaTrash
                  type="button"
                  className="text-light p-2 rounded-circle bg-secondary ms-2"
                  style={{ width: '29px', height: '29px' }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Delete
                </span>
              </div>
              {/* <div>
                <FaUserEdit
                  className="bg-primary text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  User Log
                </span>{' '}
              </div> */}
            </td>
          </tr>
          <tr>
            <td>John Smith</td>
            <td>johnsmith@gmail.com</td>
            <td>N/A</td>
            <td className="ps-3 justify-content-center text-center">
              <div className="d-flex justify-content-center align-items-center pt-1">
                <ToggleButton value={value} onToggle={handleToggle} />
              </div>
            </td>

            <td className="text-center d-flex justify-content-around" style={{ right: '10px' }}>
              {/* <div>
                <FaPen
                  className="bg-primary text-light p-2  rounded-circle"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Edit
                </span>{' '}
              </div> */}

              {/* <div>
                <FaLockOpen
                  className="text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, #A46808 0%, #ED9201 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Reset Password
                </span>{' '}
              </div> */}
              <Link to="/dashboard/UserPermissions">
                <div>
                  <FaUser
                    className="bg-primary text-light p-2  rounded-circle ms-2"
                    type="button"
                    style={{
                      width: '29px',
                      height: '29px',
                      background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                    }}
                  />
                  &nbsp;
                  <span className="text-secondary" style={{ fontSize: '12px' }}>
                    User Rights
                  </span>{' '}
                </div>
              </Link>
              <div>
                <FaTrash
                  type="button"
                  className="text-light p-2 rounded-circle bg-secondary ms-2"
                  style={{ width: '29px', height: '29px' }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Delete
                </span>
              </div>
              {/* <div>
                <FaUserEdit
                  className="bg-primary text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  User Log
                </span>{' '}
              </div> */}
            </td>
          </tr>
          <tr>
            <td>John Smith</td>
            <td>johnsmith@gmail.com</td>
            <td>N/A</td>
            <td className="ps-3 justify-content-center text-center">
              <div className="d-flex justify-content-center align-items-center pt-1">
                <ToggleButton value={value} onToggle={handleToggle} />
              </div>
            </td>

            <td className="text-center d-flex justify-content-around" style={{ right: '10px' }}>
              {/* <div>
                <FaPen
                  className="bg-primary text-light p-2  rounded-circle"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Edit
                </span>{' '}
              </div> */}

              {/* <div>
                <FaLockOpen
                  className="text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, #A46808 0%, #ED9201 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Reset Password
                </span>{' '}
              </div> */}
              <Link to="/dashboard/UserPermissions">
                <div>
                  <FaUser
                    className="bg-primary text-light p-2  rounded-circle ms-2"
                    type="button"
                    style={{
                      width: '29px',
                      height: '29px',
                      background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                    }}
                  />
                  &nbsp;
                  <span className="text-secondary" style={{ fontSize: '12px' }}>
                    User Rights
                  </span>{' '}
                </div>
              </Link>
              <div>
                <FaTrash
                  type="button"
                  className="text-light p-2 rounded-circle bg-secondary ms-2"
                  style={{ width: '29px', height: '29px' }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Delete
                </span>
              </div>
              {/* <div>
                <FaUserEdit
                  className="bg-primary text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  User Log
                </span>{' '}
              </div> */}
            </td>
          </tr>
          <tr>
            <td>John Smith</td>
            <td>johnsmith@gmail.com</td>
            <td>N/A</td>
            <td className="ps-3 justify-content-center text-center">
              <div className="d-flex justify-content-center align-items-center pt-1">
                <ToggleButton value={value} onToggle={handleToggle} />
              </div>
            </td>

            <td className="text-center d-flex justify-content-around" style={{ right: '10px' }}>
              {/* <div>
                <FaPen
                  className="bg-primary text-light p-2  rounded-circle"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Edit
                </span>{' '}
              </div> */}

              {/* <div>
                <FaLockOpen
                  className="text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, #A46808 0%, #ED9201 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Reset Password
                </span>{' '}
              </div> */}
              <Link to="/dashboard/UserPermissions">
                <div>
                  <FaUser
                    className="bg-primary text-light p-2  rounded-circle ms-2"
                    type="button"
                    style={{
                      width: '29px',
                      height: '29px',
                      background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                    }}
                  />
                  &nbsp;
                  <span className="text-secondary" style={{ fontSize: '12px' }}>
                    User Rights
                  </span>{' '}
                </div>
              </Link>
              <div>
                <FaTrash
                  type="button"
                  className="text-light p-2 rounded-circle bg-secondary ms-2"
                  style={{ width: '29px', height: '29px' }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  Delete
                </span>
              </div>
              {/* <div>
                <FaUserEdit
                  className="bg-primary text-light p-2  rounded-circle ms-2"
                  type="button"
                  style={{
                    width: '29px',
                    height: '29px',
                    background: 'linear-gradient(0deg, rgba(12,42,82,1) 0%, rgba(25,108,216,1) 100%)',
                  }}
                />
                &nbsp;
                <span className="text-secondary" style={{ fontSize: '12px' }}>
                  User Log
                </span>{' '}
              </div> */}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default UserListTable;
