import React, { useState } from 'react';

export default function Select6() {
  const [fruits, setFruits] = useState({
    apple: false,
    banana: false,
    orange: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFruits({
      ...fruits,
      [name]: checked,
    });
  };

  return (
    <div>
      <div className='d-flex align-items-start text-muted'>
      <input
          type="checkbox"
          className='mt-2 me-3'
          name="banana"
          checked={fruits.banana}
          onChange={handleCheckboxChange}
        />
        <p className='mt-0'>Configure system settings and preferences</p>
      </div>
      <div className='d-flex align-items-start text-muted'>
      <input
          type="checkbox"
          className='mt-2 me-3'
          name="banana"
          checked={fruits.banana}
          onChange={handleCheckboxChange}
        />
        <p className='mt-0'>Manage email notifications and password policies</p>
      </div>

      <div className='d-flex align-items-start text-muted'>
      <input
          type="checkbox"
          className='mt-2 me-3'
          name="banana"
          checked={fruits.banana}
          onChange={handleCheckboxChange}
        />
        <p className='mt-0'>Manage user accounts and permissions for clinics</p>
      </div>
    </div>
  );
}
