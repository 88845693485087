


import { width } from '@mui/system';
import React, { useState } from 'react';
// import './NumberInputWithButtons.css'; // Import your CSS file for styling

function NumberField() {
  const [number, setNumber] = useState(0);

  const handleIncrement = () => {
    setNumber(number + 1);
  };

  const handleDecrement = () => {
    if (number > 0) {
      setNumber(number - 1);
    }
  };

  return (
    <div className="number-input-container">
      <button className="decrement-button  px-1" onClick={handleDecrement} style={{background:'#ACACAC',border:'none', fontSize:'7px',paddingTop:'10.5px', paddingBottom:'4.7px',color:'#727272'}}>
        &#9660;
      </button>
      <input 
        style={{width:'20px',border:'none', background:'#ECECEC',textAlign:'center'}}
        type="number"
        className="custom-number-input"
        value={number}
        onChange={(e) => setNumber(parseInt(e.target.value, 10))}
        min={0}
        
      />
      <button className="increment-button px-1" onClick={handleIncrement}  style={{background:'#ACACAC',border:'none', fontSize:'7px',paddingTop:'10.5px', paddingBottom:'4.7px',color:'#727272'}}>
        &#9650;
      </button>
    </div>
  );
}

export default NumberField;
