import React, { useState } from 'react';
import { Card, Col, Container, Row, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';

const OutcomeMeasure = () => {

    const [selectedOption, setSelectedOption] = useState(''); // Initialize with an empty string or 'yes'/'no'

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };


  return (
    <div>
      <Container>
        <Row>
            <Col>
                <Card className=' shadow-sm p-4' border='0'>
                    <h4 className='fw-bold text-secondary'>Create Outcome Measures</h4>
                    <Row className='justify-content-center mt-5'>
                        <Col md={6} className='px-4'>
                            <div className='bg-light shadow-sm p-5' style={{background:'#F8F8F8'}}>
                                <h6 className='text-secondary'>Outcome Measures Title</h6>
                                <Form.Select aria-label=""  style={{height:'35px',width:'100%',border:'1px solid #e6e6e6',color:'#ccc',borderRadius:'0px'}}>
                                    <option>Enter title here</option>
                                </Form.Select>

                                <h6 className='text-secondary mt-4'>Scoring</h6>
                                <Form.Select aria-label=""  style={{height:'35px',width:'100%',border:'1px solid #e6e6e6',color:'#ccc',borderRadius:'0px'}}>
                                    <option>Select scoring method</option>
                                </Form.Select>

                                <h6 className='text-secondary mt-4'>Likert Scale</h6>
                                <label htmlFor="yesOption" className='text-secondary ' style={{fontSize:'14px'}}>
                                Yes
                                <input
                                    type="radio"
                                    id="yesOption"
                                    value="yes"
                                    checked={selectedOption === 'yes'}
                                    onChange={handleOptionChange}
                                    className='ms-3'
                                    />

                                   
                                </label>

                                <label htmlFor="noOption"  className='ms-4 text-secondary' style={{fontSize:'14px'}}>
                                No
                                    <input
                                    type="radio"
                                    id="noOption"
                                    value="no"
                                    checked={selectedOption === 'no'}
                                    onChange={handleOptionChange}
                                    className='ms-3'
                                    />
                                    
                                </label>
                            


                            <Row className='mt-5'>
                                <Col className='text-end'>
                                    <Link className='btn btn-outline-secondary w-100' 
                                            to='/dashboard/OutcomeMeasureTable' 
                                            style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', 
                                                    padding:'5px 25px',
                                                    fontSize:'13px' }}>Cancel
                                    </Link>
                                </Col>
                                <Col>
                                <Link 
                                        type="submit" 
                                        variant="contained"
                                        to='/dashboard/OutcomeMeasureTable' 
                                        className='text-light btn w-100'
                                        style={{background: 'linear-gradient(to top, #11448A 0%, #176CD9 100%)', 
                                                padding:'5px 25px',
                                                border:'none', 
                                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;'}}>Save & Continue
                                    </Link>  
                                </Col>
                            </Row>

                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OutcomeMeasure
