import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import OutcomeMeasureTableData from '../../components/tables/OutcomeMeasureTableData'

const OutcomeMeasureTable = () => {
  return (
    <div>
      <Container>
        <Row>
            <Col>
                <OutcomeMeasureTableData />
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OutcomeMeasureTable
