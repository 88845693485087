import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DateRangeSelector from './DateRangeSelector';


function CustomCelender() {
  const [selectedOption, setSelectedOption] = useState('');
  const [visibleComponents, setVisibleComponents] = useState([]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Determine which components to show based on the selected option
    switch (selectedValue) {
        case 'option4':
            setVisibleComponents([<DateRangeSelector key="1" />]);
            break;
      default:
        setVisibleComponents([]);
    }
  };

  return (
    <Container>
        <Row className='ps-0' style={{position:'relative'}}>
            <Col md={7} className='ps-0 ms-0' >
            <select onChange={handleSelectChange} value={selectedOption} className='w-100 ms-0 px-2 ' style={{height:'40px',border:'1px solid #DEE2E6',borderRadius:'5px', color:'#777'}}>
                <option value="">Date Range</option>
                <option value="option1">This Year</option>
                <option value="option2">Last Year</option>
                <option value="option4">Custom Range</option>
            </select>
            </Col>
            <Col md={5}  style={{position:'absolute',right:'0',bottom:'-210px'}}>
                {visibleComponents.map((component) => component)}
            </Col>
        </Row>
      



      
    </Container>
  );
}

export default CustomCelender;
