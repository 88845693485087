import Accordion from 'react-bootstrap/Accordion';
import Select1 from './accordionselect/Select1';
import Select2 from './accordionselect/Select2';

function UserAccordionOne() {
  return (
    <Accordion defaultActiveKey="0" style={{border:'0'}} >
      <Accordion.Item eventKey="0" style={{border:'0'}}>
        <Accordion.Header><h5 className='text-muted'>Survey Management</h5></Accordion.Header>
        <Accordion.Body>
            <Select1 />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="0" style={{border:'0'}}>
        <Accordion.Header><h5 className='text-muted'>User Management</h5></Accordion.Header>
        <Accordion.Body>
          <Select2 />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default UserAccordionOne;



