import React from 'react';
import { VictoryPie } from 'victory-pie';

const myData = [
    {x:"Some High School",y:5},
    {x:"Some college credit no degree", y:4},
    {x:"Associate degree",y:3}, 
    {x:"Bachelor's degree",y:2},
    {x:"Professional degree",y:2},
];

function PieChart2() {
    return (
        <div>
            <VictoryPie 
            style={{
                data: {
                fillOpacity: 0.9, stroke: "#fff", strokeWidth: 2,margin:0,padding:15
                },
                labels: {
                fontSize: 14, fill: "#000",margin:0,padding:15
                }
            }}
  
             data={myData}  
             colorScale={["#1761C3", "#FDA61D","#ACACAC","#1761C3", "#FDA61D"]} 
             radius={150}  
            />
        </div>

);
}

export default PieChart2;