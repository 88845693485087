import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import emojiImg from '../../assets/images/emoji.png';

const DateAndTime = () => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Form className="radio-tabs">
        <Row className="mt-4">
          <Col xs={12}>
            <div className="d-flex align-items-center mt-4">
              <input
                type="text"
                className="ps-3 w-100"
                placeholder="Enter label here"
                id="textInput"
                style={{ height: '30px', width: '100%', border: '1px solid #F3F3F3' }}
              />

              <FaPlusCircle
                type="button"
                className="ms-3"
                style={{ width: '25px', height: '25px', color: '#C3C3C3' }}
              />
              <FaMinusCircle
                type="button"
                className="ms-3"
                style={{ width: '25px', height: '25px', color: '#C3C3C3' }}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="uploadFileWrp d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="txtwrp me-4">
                  <span>Format</span>
                </div>
                <div className="inputCheck me-4 d-flex align-items-center">
                  <input type="checkbox" />
                  <span>Date Info</span>
                </div>
                <div className="inputCheck d-flex align-items-center">
                  <input type="checkbox" />
                  <span>Time Info</span>
                </div>
              </div>
              <div className='d-flex align-items-center'>
              <div className="inputCheck me-4 d-flex align-items-center">
                  <input type="radio" />
                  <span>MM/DD/YYYY</span>
                </div>
                <div className="inputCheck d-flex align-items-center">
                  <input type="radio" />
                  <span>DD/MM/YYYY</span>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
          <div className="shortTxtInput" style={{marginTop: '54px'}}>
              <input type="text" placeholder="When an invalid file type is uploaded, display this error message." />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DateAndTime;
